import React, { useState, useContext, useEffect } from "react";
import { PageEditorContext } from "../ModuleWrapper/ModuleEditorContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import PropTypes from 'prop-types';


const ckEditorConfig = {
    toolbar: [
        'Bold',
        'Italic',
        'NumberedList',
        'BulletedList',
        'Link',
        'Unlink',
        'ImageUpload',
        'doclibrary'
    ],
    link: {
        decorators: {
            openInNewTab: {
                mode: 'manual',
                label: 'Open in a new tab',
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            },
        },
        addTargetToExternalLinks: true,
        defaultProtocol: 'http://',
        validate: function (url) {

            return /^(?:(?:https?|ftp):\/\/)?(?:\/)?(?:[^@\n]+@)?(?:www\.)?[^.]+\.[^.]+/.test(url);
        }
    },
    image: {
        resizeOptions: false,
        toolbar: []
    }
};
const FileRepository = {
    createUploadAdapter: (loader) => {
        return new UploadAdapter(loader);
    },
};

class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }
    upload() {
        return new Promise((resolve) => {
            const imageUrl = this.loader.data;
            resolve({ default: imageUrl });
        });
    }
}
export const Freeform = ({ id, content }) => {
  const [editorData, setEditorData] = useState("");
  const { updateModuleContent } = useContext(PageEditorContext);

  useEffect(() => {
    if(editorData){
      updateModuleContent(id, editorData);
    }
  }, [editorData]);
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
    Freeform.propTypes = {
        id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    };
  return (
    <div>
      <CKEditor
        editor={Editor}
        config={ckEditorConfig}
        data={editorData}
        onChange={handleEditorChange}
        onReady={(editor) => {
      
              editor.setData(content);
              editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
                  FileRepository.createUploadAdapter(loader);
           


              }}

          />
         
    </div>
  );
};

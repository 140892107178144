import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdminError from '../../../components/admin/admin-error';
import AdminTextInput from '../../../components/admin/admin-text-input.js';
import MediaUploader from '../../../components/multipleMedia/MediaUploader.js';
import { newsItem } from './newsItem';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCompanies } from '../../../store/employee/actions';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import { submitNews, getNewsByIdEdit } from '../../../api/news';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useAdminMenuContext } from "../../../context/AdminMenuContext";

const ckEditorConfig = {
    toolbar: [
        'Bold',
        'Italic',
        'Link',
        'Unlink',
        'NumberedList',
        'BulletedList',
        'ImageUpload',
        'doclibrary'
    ],
    link: {
        decorators: {
            openInNewTab: {
                mode: 'manual',
                label: 'Open in a new tab',
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            },
        },
        addTargetToExternalLinks: true,
        defaultProtocol: 'http://',
        validate: function (url) {

            return /^(?:(?:https?|ftp):\/\/)?(?:\/)?(?:[^@\n]+@)?(?:www\.)?[^.]+\.[^.]+/.test(url);
        },
    },
    image: {
        resizeOptions: false,
        toolbar: []
    }
};

const FileRepository = {
    createUploadAdapter: (loader) => {
        return new UploadAdapter(loader);
    },
};

class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }
    upload() {
        return new Promise((resolve) => {
            const imageUrl = this.loader.data;
            resolve({ default: imageUrl });
        });
    }
}

export function AddEditNews() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [news, setNews] = useState(newsItem);
    const { companiesList } = useSelector(state => state.employee);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [mediaItems, setMediaItems] = useState([]);
    const { newsBaseId } = useParams();
    const [selectAll, setSelectAll] = useState(false);
    const [publishDateInputValue, setPublishDateInputValue] = useState(news?.publishDate ? new Date(news.publishDate) : new Date());
    const [editLink, setEditLink] = useAdminMenuContext('');

    useEffect(() => {
        if (newsBaseId > 0) {
            dispatch(showLoading());
            getNewsByIdEdit(newsBaseId).then((response) => {
                let newsResponse = response.data.data;
                setNews(newsResponse);
                setPublishDateInputValue(newsResponse?.publishDate ? new Date(newsResponse.publishDate) : new Date());
                setSelectedOrganization(newsResponse.newsCompanies);
                if (companiesList.length == newsResponse.newsCompanies.length) {
                    setSelectAll(true);
                }
                if (newsResponse.media !== null && newsResponse.media.length > 0) {
                    var getMediaItems = [];
                    newsResponse.media.forEach((item) => {
                        item.mediaItem.mediaId = item.mediaId;
                        getMediaItems.push(item.mediaItem);
                    });
                    setMediaItems(getMediaItems);
                }
                setEditLink(`/news-details/${newsBaseId}`);
                console.log(editLink)
                dispatch(hideLoading());
            });
        }
        else {
            setEditLink('');
        }
    
    }, []);


    const [validation, setValidation] = useState({
        newsTitle: true,
        newsOrganization: true
    });

    useEffect(() => {
        document.title = 'Happenings | CWP Employee Portal';
        dispatch(getCompanies());
    }, []);
    const handleOrganizationCompanyToggle = (e, companyId) => {
        var checked = e.target.checked;
        let updatedOrganization;

        if (checked) {
            updatedOrganization = [...selectedOrganization, { companyId }];
            if (companiesList.length == updatedOrganization.length) {
                setSelectAll(true);
            }
        } else {
            updatedOrganization = selectedOrganization.filter(item => item.companyId !== companyId);
            if (selectAll) {
                setSelectAll(false);
            }
        }
        setSelectedOrganization(updatedOrganization);
    }
    const handleAllCheckboxToggle = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        const updatedSelectedOrg = isChecked
            ? companiesList.map(company => ({ companyId: company.id }))
            : [];
        setSelectedOrganization(updatedSelectedOrg);
    };

    const handleNewsSave = () => {
        dispatch(showLoading());
        news.newsCompanies = selectedOrganization;
        var isValid = true;

        var validate = {
            newsTitle: '',
            titleError: '',
            newsOrganization: '',
            organizationError: ''

        };
        if (news == null || news.title == null || news.title == "") {
            validate.newsTitle = false;
            validate.titleError = "Title is required";
            isValid = false;
        }
        if (news == null || news.newsCompanies == null || news.newsCompanies == "") {
            validate.newsOrganization = false;
            validate.organizationError = "At least select one organization";
            isValid = false;
        }

        setValidation({ ...validation, ...validate });
        if (isValid == false) {
            dispatch(hideLoading());
            return;
        }

        submitNews(news)
            .then((response) => {
                if (response.data.success == false) {
                    dispatch(hideLoading());
                    setError(response.data.message);    
                    return;
                }
                if (response.data.success) {
                    dispatch(hideLoading());
                    navigate('/siteadmin/news');
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                if (error.response.data && error.response.data.message) {
                    setError(error.response.data.message);
                } else {
                    setError(error.response.responseText);
                }
            });
    };

    const handleMediaUpdates = (mediaItems) => {
        setNews({
            ...news,
            media: mediaItems,
        });
    };


    const handleDateFormate = (date) => {
        const inputDate = date;
        let formattedDate = inputDate.replace(/\D/g, ''); // Remove all non-digit characters
        if (formattedDate.length > 2 && formattedDate.length <= 4) {
            formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2);
        } else if (formattedDate.length > 4) {
            formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2, 4) + '/' + formattedDate.slice(4, 8);
        }
        return formattedDate;
    };

    const isValidDateFormat = (dateString) => {
        // Define a regular expression pattern for the desired format (mm/dd/yyyy)
        const dateFormatPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

        // Check if the dateString matches the pattern
        return dateFormatPattern.test(dateString);
    }

    const publishDateDateChange = (event) => {
        if (event.type === "change") {
            const formattedDate = handleDateFormate(event.target.value);
            setPublishDateInputValue(formattedDate);
            if (isValidDateFormat(formattedDate)) {            
                setNews({ ...news, publishDate: new Date(formattedDate) });     
            }
        }
    };

    const handlePaste = (event, editor) => {
        const pastedData = event.clipboardData.getData('text/plain');
        const currentData = editor.getData();
        const newData = currentData + pastedData;

        setNews((prevEvent) => ({ ...prevEvent, content: newData }));
        setIsInitialLoad(false);
    };

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin/news'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">Add/Edit Happenings</h1>
                <AdminError
                    message={error}
                    onClose={() => {
                        setError('');
                    }}
                ></AdminError>
                <p className="admin-section-small-headline">Title & Images/Videos</p>
                <div className="admin-section admin-section-narrow">
                    <div className="admin-section-row">
                        <div className="admin-section-cell wide">
                            <AdminTextInput
                                label="Title of article"
                                id="title-News"
                                value={news?.title ?? ''}
                                isRequired={true}
                                onChange={(value) => {
                                    setNews({ ...news, title: value });
                                    setValidation({ ...validation, newsTitle: value != '' });
                                }}
                                validated={validation.newsTitle}
                                requiredText={validation.titleError}
                            />
                        </div>
                        <div className="admin-section-cell wide">
                            <label className="admin-checkbox-label">
                                <input
                                    className="admin-inline-checkbox"
                                    type="checkbox"
                                    checked={news && news.hrNews}
                                    onChange={(e) => {
                                        setNews({ ...news, hrNews: e.target.checked });
                                    }}
                                />
                                <span className="admin-inline-label-text">Show as a &quot;HR Happenings&quot;</span>
                            </label>
                        </div>
                    </div>
                    <div className="admin-section-row">
                        <div className="admin-section-cell full">
                            <label className="admin-default-label">
                                Organization<span>*</span>
                            </label>
                            {!validation.newsOrganization && (
                                <p className="input-validation-error"><span className="validation-error-message">{validation.organizationError}</span></p>
                            )}
                            <div className="admin-column-wrap auto-columns">
                                <label className="admin-checkbox-label inline">
                                    <input
                                        className="admin-inline-checkbox"
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={handleAllCheckboxToggle}
                                    ></input>
                                    All
                                </label>
                                {companiesList && companiesList.map(company => (
                                    <label
                                        className="admin-checkbox-label inline"
                                        key={company?.id}>
                                        <input
                                            className="admin-inline-checkbox"
                                            type="checkbox"
                                            checked={selectedOrganization.some(x => x.companyId === company.id)}
                                            onChange={(e) => {
                                                handleOrganizationCompanyToggle(e, company.id);
                                                setValidation({ ...validation, newsOrganization: e != '' });
                                            }}></input>
                                        {company.abbreviatedName}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div>

                        <div className="admin-section-row">
                            <div className="admin-section-cell">
                                <label className="admin-default-label">Set Publish Date</label>
                                <DatePicker
                                    className="admin-default-input-text"
                                    selected={news?.publishDate ? new Date(news.publishDate) : new Date()}
                                    placeholder="mm/dd/yyyy"
                                    autoComplete="off"
                                    onChange={(date) => {
                                        setNews({ ...news, publishDate: date });                                       
                                    }}
                                    onChangeRaw={publishDateDateChange}
                                    value={publishDateInputValue}
                                />
                            </div>
                        </div>

                    </div>                   

                    <div className="admin-section-row circle">
                        <div className="admin-section-cell full">
                            <p className="admin-section-small-headline">Image/Video</p>                          
                            <MediaUploader
                                isPlaceHolder={false}
                                requireAltText={false}
                                imageOnly={false}
                                videoOnly={false}
                                forceCrop={false}
                                allowGallery={true}
                                mediaItems={mediaItems}
                                saveMediaItems={handleMediaUpdates}
                                allowSorting={true}
                                sortInTable="NewsMedia"
                            />
                        </div>
                    </div>

                    <div className="admin-section-row">
                        <div
                            className={
                                'admin-section-cell extra-wide'
                            }
                        >
                            <label className="admin-default-label" htmlFor="short-desc">
                                Happenings Article Content
                            </label>
                            <CKEditor
                  editor={Editor}
                                config={ckEditorConfig}
                                data={news && news.content || ''}
                                onReady={(editor) => {
                                    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
                                        FileRepository.createUploadAdapter(loader);
                                }}
                                onChange={(newss, editor) => {
                                    const data = editor.getData();
                                    setNews((prevNews) => ({ ...prevNews, content: data }));
                                    if (!isInitialLoad) {                          
                                        console.log("data", data); 
                                    }
                                    setIsInitialLoad(false);
                                }}
                                onPaste={handlePaste}
                            />
                        </div>
                    </div>

                </div>

                <div className="admin-floating-button-wrap">
                    <button
                        className="admin-floating-button"
                        onClick={() =>
                            navigate('/siteadmin/news')
                        }
                    >
                        Cancel
                    </button>
                    <button
                        className="admin-floating-button power"
                        onClick={handleNewsSave}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
}
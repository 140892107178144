import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './financial-scoreboard.css';
import Navigation from '../../components/navigation/Navigation.jsx';
import { GetAllFinancialReport } from '../../api/finanicalapi';
import { getCompanies } from '../../store/employee/actions';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import HeaderProfile from '../../components/HeaderProfile';
import { Scrollbar } from 'react-scrollbars-custom';
import { startOfMonth, getWeek, startOfWeek, isSameMonth, endOfMonth, eachDayOfInterval, getDay, addDays, isBefore } from 'date-fns';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import { formatUSD } from "../../helpers/formatterHelper";
import PortalAlert from '../../components/portalAlert/portalAlert';
import { selectedScoreboardCompany } from '../../store/finanacialscoreboard/index';
export default function FinancialScoreboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { primaryCompanyId } = useSelector(state => state.auth);
    const [finanicals, setFinanicals] = useState([]);
    const companiesList = useSelector(state => state.employee.companiesList);
    const { selectedCompanyId } = useSelector(state => state.financialScoreboard);
    const [filter, setFilter] = useState({ companyId: (primaryCompanyId !== undefined && primaryCompanyId !== null ? selectedCompanyId === 0 ? primaryCompanyId : selectedCompanyId : selectedCompanyId === 0 ? 4 : selectedCompanyId), timezone: GetTimeZone() });
    const [isChecked, setIsChecked] = useState(false);
    const [selectedFirstCompany, setSelectedFirstCompany] = useState(0);

    let week1Total = 0;
    let week2Total = 0;
    let week3Total = 0;
    let week4Total = 0;
    let week5Total = 0;

    const handleToggle = () => {
        setIsChecked(!isChecked);
        if (isChecked === false)
            navigate('/bonuslevels');
        else
            navigate('/financial-scoreboard');
    };
    useEffect(() => {
        document.title = 'Financial Scoreboard | CWP Team Connect'
        dispatch(showLoading());
        let defaultComany = 0;
        dispatch(getCompanies()).unwrap().then((response) => {
            if (response && response.success) {
                let companyFirstRow = response.data[0];
                if (primaryCompanyId === 0) {
                    setSelectedFirstCompany(companyFirstRow.id);
                    defaultComany = companyFirstRow.id;
                    setFilter({
                        ...filter,
                        companyId: defaultComany
                    });
                }
            }
        });

        if (primaryCompanyId === 0 && selectedCompanyId !==0) {
            setFilter({
                ...filter,
                companyId: (primaryCompanyId !== undefined && primaryCompanyId !== null ?
                    selectedCompanyId === 0 ? primaryCompanyId : selectedCompanyId
                    : selectedCompanyId === 0 ? 4 : selectedCompanyId
                )
            });
        }
        
    }, []);

    useEffect(() => {
        if (selectedCompanyId > 0) {
            setFilter({
                ...filter,
                companyId: selectedCompanyId
            });
        }

    }, [selectedCompanyId]);

    useEffect(() => {
        GetAllFinancialReport({ options: { data: filter } }).then((response) => {
            if (response.data.success) {
                setFinanicals(response.data.data);           
            }
            dispatch(hideLoading());
        });
    }, [filter, selectedFirstCompany]);

    const handleCompanyChange = (e) => {
        const selectedCompanyId = parseInt(e.target.value, 10);
        dispatch(selectedScoreboardCompany({ companyId: selectedCompanyId }));
        setFilter({ ...filter, companyId: selectedCompanyId });
    };



    const getCurrentWeek = () => {
        const date = new Date();

        const startDateOfMonth = startOfMonth(date);
        const startWeekNumber = getWeek(startDateOfMonth, { weekStartsOn: 1 });
        const startDateOfWeek = startOfWeek(date, { weekStartsOn: 1 });
        const isInCurrentMonth = isSameMonth(startDateOfWeek, startDateOfMonth);

        let currentWeekNumber;
        if (isInCurrentMonth) {
            currentWeekNumber = getWeek(date, { weekStartsOn: 1 }) - startWeekNumber + 1;
        } else {
            currentWeekNumber = 5; // Assuming maximum of 5 weeks in a month
        }

        return currentWeekNumber;
    }

    const countMondaysInMonth = () => {
        const date = new Date();
        const startOfMonthDate = startOfMonth(date);
        const endOfMonthDate = endOfMonth(date);

        // Get all days between start and end of the month
        const daysOfMonth = eachDayOfInterval({ start: startOfMonthDate, end: endOfMonthDate });

        // Count Mondays
        const mondayCount = daysOfMonth.reduce((count, day) => {
            if (getDay(day) === 1) { // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
                return count + 1;
            }
            return count;
        }, 0);

        return mondayCount;
    };

    const getCurrentMonthWeekNumber = () => {
        const date = new Date();
        const startOfMonthDate = startOfMonth(date);

        let currentWeekNumber = 0;
        let currentDate = startOfMonthDate;

        // Iterate through each day starting from the start of the month
        while (isSameMonth(currentDate, date) && isBefore(currentDate, date) || currentDate.getDate() === date.getDate()) {
            if (getDay(currentDate) === 1) { // Check if it's Monday
                currentWeekNumber++;
            }
            // Move to the next day
            currentDate = addDays(currentDate, 1);
        }

        return currentWeekNumber;
    };


    const week = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].week : 0;
    const weekMonth = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].weekMonth : null;
    const weekYear = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].weekYear : 0;
    const budgetMonthName = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].budgetMonthName : null;
    const budgetYear = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].budgetYear : 0;

    finanicals?.forEach(financialItem => {
        financialItem.subTypes.forEach(subType => {
            week1Total += subType.week1;
            week2Total += subType.week2;
            week3Total += subType.week3;
            week4Total += subType.week4;
            week5Total += subType.week5;
        });
    });


    const formatWeek = (subType, week, weekTotal, weekNumber) => {
        if (subType.subType === 'Operating Income %') {
            if (getCurrentWeek() >= weekNumber && week === 0) {
                if (weekTotal > 0) {
                    return formatUSD(week);
                } else {
                    return "Pending";
                }
            } else {
                return `${week}%`;
            }
        } else {
            if (week < 0) {
                return `-${formatUSD(Math.abs(week))}`;
            } else if (getCurrentWeek() >= weekNumber && week === 0) {
                if (weekTotal > 0) {
                    return formatUSD(week);
                } else {
                    return "Pending";
                }
            } else {
                return formatUSD(week);
            }
        }
    };


    return (
        <div className="directory-wrap events-wrap financial-scoreboard-desktop">
            <div className="drawer-menu-collapse-wrap"></div>
            <Navigation />
            <div className="events-page-wrap">
                <div className="page-inner events-inner">
                    <div className="inner-pages-alert">
                        <PortalAlert />
                    </div>
                    <div className="event-content-page">
                    <div className="profile-wrap-outer">
                        <HeaderProfile />
                    </div>

                    <div className="monitors-header-wrap">
                        <div className="scoreboard-header-left">
                            <h1 className="page-title monitors-header-title">Financial Scoreboard</h1>
                        </div>

                        <div className="header-right-wrap">
                            <div className={`toogle-button ${isChecked ? 'togglechecked' : ''}`}>
                                <label className="toggle-label scorelabel" htmlFor="switch" onClick={() => navigate('/financial-scoreboard')}>Scoreboard</label>
                                <label className="toggle-label bonuslabel" htmlFor="switch" onClick={() => navigate('/bonuslevels')}>Bonuses</label>
                                <input className="toggle-input"
                                    type="checkbox"
                                    id="switch"
                                    checked={isChecked}
                                    onChange={handleToggle}
                                />
                            </div>
                            <div className="filter-bars">
                                <label>
                                    <select
                                        className="directory-filters menu-wrap org-dropdown"
                                        onChange={handleCompanyChange}
                                        value={filter.companyId}
                                    >                                
                                        {companiesList && companiesList.map((company) => {
                                            return (
                                                <option value={company.id} name={company.name} key={company.id}>{company.abbreviatedName}</option>
                                            )
                                        })}
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="financial-scoreboard-table-wrap custom-select-scroll scrollbar-inner">

                        <Scrollbar style={{ '.ScrollbarsCustom-ThumbX': { background: '#fdfdfd' } }}
                            wrapperProps={{ className: 'custom-scrollbar-wrapper' }}
                            trackYProps={{ className: 'custom-scrollbar-track-y' }}
                            thumbYProps={{ className: 'custom-scrollbar-thumb-y' }}
                            trackXProps={{ className: 'custom-scrollbar-track-x' }}
                            thumbXProps={{ className: 'custom-scrollbar-thumb-x' }}>

                            <table className="financial-scoreboard-table">
                                <thead>
                                    <tr>
                                        <th className="fixed-column">
                                        </th>
                                        <th className="fixed-second-column">&nbsp;</th>
                                        <th className="font-700 budget-grad-first-child ">  {finanicals.length > 0 ? finanicals[0].monthBudgetName : new Date().toLocaleString('default', { month: 'long' })} Budget</th>
                                        <th className="font-700 grad-first-child td-not-wrap ">
                                            <p>Week 1</p>
                                        </th>
                                        <th className="expanded-col font-700 td-not-wrap">
                                            <p>Week 2</p>
                                        </th>
                                        <th className="expanded-col font-700 td-not-wrap">
                                            <p>Week 3</p>
                                        </th>
                                        <th className={`expanded-col font-700 td-not-wrap ${countMondaysInMonth() >= getCurrentWeek() ? '' : 'grad-last-child'}`}>
                                            <p>Week 4</p>
                                        </th>

                                        {countMondaysInMonth() >= getCurrentWeek() ?
                                            <th className="expanded-col font-700 grad-last-child td-not-wrap">
                                                <p>Week 5</p>
                                            </th>
                                            : ''
                                        }

                                        <th className="font-700">
                                            <p>{(budgetMonthName != undefined && budgetMonthName != null) ? `${budgetMonthName} ${new Date().getFullYear() !== parseInt(budgetYear) ? budgetYear : ""}` : ""} Consolidated Budget</p>
                                        </th>
                                        <th className="font-700"> {week === 0 ? "Weekly Consolidated" : `${weekMonth} ${new Date().getFullYear() !== parseInt(weekYear) ? weekYear : ""} Week ${week} Consolidated`}</th>
                                        <th className="font-700 td-last-row-child">Variance</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {finanicals.map((financialItem, index) => (

                                        <React.Fragment key={index}>
                                            <tr className="first-row">
                                                <td className="fixed-column sales-cell font-700" rowSpan={financialItem.subTypes.filter(subType => !subType.subType.toLowerCase().includes('operating income')).length + 1}>
                                                    <span className="fixed-column-rotate-text">{financialItem?.type}</span>
                                                </td>
                                            </tr>

                                            {financialItem.subTypes
                                                .filter(subType => !subType.subType.toLowerCase().includes('operating income'))
                                                .map((subType, subIndex) => (
                                                    <tr key={`${index}-${subType.subType}-${subIndex}`} className={subType.subType === 'Total Sales' ? "" : subType.subType.includes('Total') || subType.subType.includes('Gross') ? "selected-row" : subType.subType.includes('Operating Income') ? "green-selected-row" : ""}>
                                                        <td className="text-align-left font-700 border-top fixed-second-column">{subType.subType}</td>

                                                        {((subType.subType !== 'Total Sales' && subType.subType.includes('Total')) || subType.subType.includes('Gross') || subType.subType.includes('Operating Income')) ? (
                                                            <>
                                                                <td className="font-400 budget-dark-gray-grad">
                                                                    {subType.subType === 'Operating Income %' ? subType.totalBudgetAmount + "%" : subType.totalBudgetAmount < 0 ? "-" + formatUSD(Math.abs(subType.totalBudgetAmount)) : formatUSD(subType.totalBudgetAmount)}
                                                                </td>
                                                                <td className={`font-${getCurrentMonthWeekNumber() == 1 ? 700 : 400} ${getCurrentMonthWeekNumber() === 1 && (getCurrentWeek() >= 1 && subType.week1 === 0) ? 'statusPending' : ''} dark-gray-grad`}>
                                                                    {formatWeek(subType, subType.week1, week1Total, 1)}                                                                    
                                                                </td>
                                                                <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 2 ? 700 : 400} ${getCurrentMonthWeekNumber() === 2 && (getCurrentWeek() >= 2 && subType.week2 === 0) ? 'statusPending' : ''} dark-gray-grad`}>
                                                                    {formatWeek(subType, subType.week2, week2Total, 2)}                                                                    
                                                                </td>
                                                                <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 3 ? 700 : 400} ${getCurrentMonthWeekNumber() === 3 && (getCurrentWeek() >= 3 && subType.week3 === 0) ? 'statusPending' : ''} dark-gray-grad`}>
                                                                    {formatWeek(subType, subType.week3, week3Total, 3)}                                                                    
                                                                </td>
                                                                <td className={`expanded-col ${countMondaysInMonth() >= getCurrentWeek() ? '' : 'grad-last-child'} ${getCurrentMonthWeekNumber() === 4 && (getCurrentWeek() >= 4 && subType.week4 === 0) ? 'statusPending' : ''} font-${getCurrentMonthWeekNumber() == 4 ? 700 : 400} dark-gray-grad `}>
                                                                    {formatWeek(subType, subType.week4, week4Total, 4)}                                                                   
                                                                </td>
                                                                {countMondaysInMonth() >= getCurrentWeek() ?
                                                                    <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 5 ? 700 : 400} ${getCurrentMonthWeekNumber() === 5 && (getCurrentWeek() >= 5 && subType.week5 === 0) ? 'statusPending' : ''} dark-gray-grad grad-last-child `}>
                                                                        {formatWeek(subType, subType.week5, week5Total, 5)}                                                                        
                                                                    </td>
                                                                    : ''
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td className={`font-700 budget-dark-grad`}>
                                                                    {subType.subType === 'Operating Income %' ? subType.totalBudgetAmount + "%" : subType.totalBudgetAmount < 0 ? "-" + formatUSD(Math.abs(subType.totalBudgetAmount)) : formatUSD(subType.totalBudgetAmount)}
                                                                </td>
                                                                <td className={`font-${getCurrentMonthWeekNumber() == 1 ? 700 : 400} ${getCurrentMonthWeekNumber() === 1 && (getCurrentWeek() >= 1 && subType.week1 === 0) ? 'statusPending' : ''} dark-grad`}>
                                                                    {formatWeek(subType, subType.week1, week1Total, 1)}                                                                    
                                                                </td>
                                                                <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 2 ? 700 : 400} ${getCurrentMonthWeekNumber() === 2 && (getCurrentWeek() >= 2 && subType.week2 === 0) ? 'statusPending' : ''} dark-grad`}>
                                                                    {formatWeek(subType, subType.week2, week2Total, 2)}                                                                    
                                                                </td>
                                                                <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 3 ? 700 : 400} ${getCurrentMonthWeekNumber() === 3 && (getCurrentWeek() >= 3 && subType.week3 === 0) ? 'statusPending' : ''} dark-grad `}>
                                                                    {formatWeek(subType, subType.week3, week3Total, 3)}                                                                    
                                                                </td>
                                                                <td className={`expanded-col ${countMondaysInMonth() >= getCurrentWeek() ? '' : 'grad-last-child'} ${getCurrentMonthWeekNumber() === 4 && (getCurrentWeek() >= 4 && subType.week4 === 0) ? 'statusPending' : ''} font-${getCurrentMonthWeekNumber() == 4 ? 700 : 400} dark-grad`}>
                                                                    {formatWeek(subType, subType.week4, week4Total, 4)}                                                                    
                                                                </td>
                                                                {countMondaysInMonth() >= getCurrentWeek() ?
                                                                    <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 5 ? 700 : 400} ${getCurrentMonthWeekNumber() === 5 && (getCurrentWeek() >= 5 && subType.week5 === 0) ? 'statusPending' : ''} dark-grad grad-last-child`}>
                                                                        {formatWeek(subType, subType.week5, week5Total, 5)}                                                                       
                                                                    </td>
                                                                    : ''
                                                                }

                                                            </>)}
                                                        <td className="font-400">
                                                            {subType.subType === 'Operating Income %' ? subType.consolidated + "%" : subType.consolidated < 0 ? "-" + formatUSD(Math.abs(subType.consolidated)) : formatUSD(subType.consolidated)}
                                                        </td>
                                                        <td className="font-700">
                                                            {subType.subType === 'Operating Income %' ? subType.weeklyConsolidated + "%" : subType.weeklyConsolidated < 0 ? "-" + formatUSD(Math.abs(subType.weeklyConsolidated)) : formatUSD(subType.weeklyConsolidated)}
                                                        </td>
                                                        <td className="font-400 td-last-row-child">
                                                            {subType.subType === 'Operating Income %' ? subType.variance + "%" : subType.variance < 0 ? "-" + formatUSD(Math.abs(subType.variance)) : formatUSD(subType.variance)}
                                                        </td>
                                                    </tr>
                                                ))}

                                        </React.Fragment>
                                    ))
                                    }

                                    {finanicals.map((financialItem, index) => (
                                        <React.Fragment key={index}>
                                            {financialItem.subTypes
                                                .filter(subType => subType.subType.toLowerCase().includes('operating income'))
                                                .map((subType, subIndex) => (
                                                    <tr key={`${index}-${subType.subType}-${subIndex}`} className={subType.subType.toLowerCase().includes('operating income') ? "green-selected-row first-green-selected" : "green-selected-row"}>
                                                        {subIndex === 0 && (
                                                            <td className="fixed-column sales-cell blank-rowspan" rowSpan="2"></td>
                                                        )}

                                                        <td className="text-align-left font-700 fixed-second-column">{subType.subType}</td>
                                                        <td className="font-400 budget-dark-green-grad">
                                                            {subType.subType === 'Operating Income %' ? subType.totalBudgetAmount + "%" : subType.totalBudgetAmount < 0 ? "-" + formatUSD(Math.abs(subType.totalBudgetAmount)) : formatUSD(subType.totalBudgetAmount)}
                                                        </td>
                                                        <td className={`font-${getCurrentMonthWeekNumber() == 1 ? 700 : 400} ${getCurrentMonthWeekNumber() === 1 && (getCurrentWeek() >= 1 && subType.week1 === 0) ? 'statusPending' : ''} dark-green-grad `}>
                                                            {formatWeek(subType, subType.week1, week1Total, 1)}                                                            
                                                        </td>
                                                        <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 2 ? 700 : 400} ${getCurrentMonthWeekNumber() === 2 && (getCurrentWeek() >= 2 && subType.week2 === 0) ? 'statusPending' : ''} dark-green-grad `}>
                                                            {formatWeek(subType, subType.week2, week2Total, 2)}                                                           
                                                        </td>
                                                        <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 3 ? 700 : 400} ${getCurrentMonthWeekNumber() === 3 && (getCurrentWeek() >= 3 && subType.week3 === 0) ? 'statusPending' : ''} dark-green-grad `}>
                                                            {formatWeek(subType, subType.week3, week3Total, 3)}                                                            
                                                        </td>
                                                        <td className={`expanded-col ${countMondaysInMonth() >= getCurrentWeek() ? '' : 'grad-last-child'} font-${getCurrentMonthWeekNumber() == 4 ? 700 : 400} ${getCurrentMonthWeekNumber() === 4 && (getCurrentWeek() >= 4 && subType.week4 === 0) ? 'statusPending' : ''} dark-green-grad `}>
                                                            {formatWeek(subType, subType.week4, week4Total, 4)}                                                           
                                                        </td>
                                                        {countMondaysInMonth() >= getCurrentWeek() ?
                                                            <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 5 ? 700 : 400} ${getCurrentMonthWeekNumber() === 5 && (getCurrentWeek() >= 5 && subType.week5 === 0) ? 'statusPending' : ''} dark-green-grad grad-last-child`}>
                                                                {formatWeek(subType, subType.week5, week5Total, 5)}                                                              
                                                            </td>
                                                            : ''
                                                        }
                                                        <td className="font-400"> {subType.subType === 'Operating Income %' ? subType.consolidated + "%" : subType.consolidated < 0 ? "-" + formatUSD(Math.abs(subType.consolidated)) : formatUSD(subType.consolidated)}</td>
                                                        <td className="font-700"> {subType.subType === 'Operating Income %' ? subType.weeklyConsolidated + "%" : subType.weeklyConsolidated < 0 ? "-" + formatUSD(Math.abs(subType.weeklyConsolidated)) : formatUSD(subType.weeklyConsolidated)}</td>
                                                        <td className="font-400 td-last-row-child">  {subType.subType === 'Operating Income %' ? subType.variance + "%" : subType.variance < 0 ? "-" + formatUSD(Math.abs(subType.variance)) : formatUSD(subType.variance)}</td>
                                                    </tr>
                                                ))}

                                        </React.Fragment>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </Scrollbar>

                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './newsDetail.css';
import Navigation from '../../components/navigation/Navigation';
import { useParams, useNavigate } from 'react-router-dom';
import leftArrow from '../../assets/arrow_back.svg';
import PlayIcon from '../../assets/play_arrow.png';
import { getNewsByIdEdit } from '../../api/news';
import { formatDate } from '../../helpers/formatterHelper';
import Slider from "react-slick";
import nextArrow from '../../assets/arrow_next.svg';
import prevArrow from '../../assets/arrow_prev.svg';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import { processUrl, isVimeoVideoExists } from '../../helpers/formatterHelper';
import PortalAlert from '../../components/portalAlert/portalAlert';
import { getVimeoToken } from '../../api/docCenterApi';

const DynamicHTMLRenderer  = (content) => {
	const htmlContent = content;

	// Create an array to hold the JSX elements
	const elements = [];
	// Parse the HTML content and generate React elements
	const parser = new DOMParser();
	const parsedHTML = parser.parseFromString(htmlContent, 'text/html');

	for (const node of parsedHTML.body.childNodes) {
		elements.push(React.createElement(node.tagName, {
			key: elements.length,
			className: 'event-pera',
			dangerouslySetInnerHTML: { __html: node.innerHTML }
			
		}));
	}

	return (
		<React.Fragment>
			{elements}
		</React.Fragment>
	);
}

export default function NewsDetail() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [news, setNews] = useState({});
	const { baseId } = useParams();
	const [openModal, setOpenModal] = useState("");
	const [previewImage, setPreviewImage] = useState("");
	const [type, setType] = useState(0);
	const [videoUrl, setVideoUrl] = useState("");
	const [currentIndex, setCurrentIndex] = useState(0);
	//const [slide, setSlide] = useState(0);
	const [processedItems, setProcessedItems] = useState([]);
	const [vimeoToken, setVimeoToken] = useState("");


	const openVideo = (mediaItem, index) => {
		setOpenModal(!openModal)
		setPreviewImage(mediaItem?.previewsrc);
		setType(mediaItem?.type);
		if (mediaItem?.type == 1) {


			const mediaData = processUrl(mediaItem?.videoSrc);
			
			let videoUrl = '';
			if (mediaItem.videoType === 0) {
				videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
			}
			else {
				if (mediaData.type === "vimeo") {
					videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true&loop=1`;
				}
				if (mediaData.type === "htmlVideo") {
					videoUrl = mediaItem?.videoSrc;
				}
			}


			setVideoUrl(videoUrl);
		}
		setCurrentIndex(index);
	}
	useEffect(() => {
		if (news?.title) {
			document.title = news?.title + ' | Happenings | CWP Team Connect';
		}
	}, [news?.title]);
	useEffect(() => { 
		dispatch(showLoading());

		getVimeoToken().then((response) => {
			setVimeoToken(response.data);
		});

		if (baseId > 0) {
			getNewsByIdEdit(baseId).then((response) => {
				let newsResponse = response.data.data;
				setNews(newsResponse);
				//setSlide(newsResponse.media.length);
				dispatch(hideLoading());
			});
		}
	}, [baseId]);
	

	useEffect(() => {
		const processItems = async () => {
			const itemsWithVideoCheck = await Promise.all(
				news.media.filter(item => !item.mediaItem?.videoThumbnailUrl?.includes("/static/media/")).map(async (item) => {
					if (item.mediaItem?.videoType === 1) {
						const videoExists = await isVimeoVideoExists(item.mediaItem?.videoId, vimeoToken);
						return { ...item, videoExists };
					}
					return item;
				})
			);
			setProcessedItems(itemsWithVideoCheck.filter(x => (x.videoExists === true && x.mediaItem?.videoType === 1) || x.mediaItem?.videoType === 0 || x.mediaItem?.type === 0));
		};

		if (news && news.media && news.media.length > 0) {
			processItems();
		}
	}, [news]);


	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		variableWidth: true,
		slidesToShow: 1,
		slidesToScroll: 1
	};


	const nextSlide = () => {
		const newIndex = currentIndex === processedItems.length - 1 ? 0 : currentIndex + 1;
		const media = processedItems[newIndex];
		if (media) {
			setPreviewImage(media.mediaItem.previewsrc);
			setType(media.mediaItem.type);
			if (media.mediaItem.type == 1) {

				const mediaData = processUrl(media.mediaItem?.videoSrc);
			

				let videoUrl = '';
				if (media.mediaItem.videoType === 0) {
					videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
				}
				else {
					if (mediaData.type === "vimeo") {
						videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true&loop=1`;
					}
					if (mediaData.type === "htmlVideo") {
						videoUrl = media.mediaItem?.videoSrc;
					}
				}	
				setVideoUrl(videoUrl);
			}
		}
		setCurrentIndex(newIndex);	
	};

	const prevSlide = () => {
		const newIndex = currentIndex === 0 ? processedItems.length - 1 : currentIndex - 1;		
		const media = processedItems[newIndex];
		if (media) {
			setPreviewImage(media.mediaItem.previewsrc);
			setType(media.mediaItem.type);
			if (media.mediaItem.type == 1) {
				const mediaData = processUrl(media.mediaItem?.videoSrc);

	
				let videoUrl = '';
				if (media.mediaItem.videoType === 0) {
					videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
				}
				else {
					if (mediaData.type === "vimeo") {
						videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true&loop=1`;
					}
					if (mediaData.type === "htmlVideo") {
						videoUrl = media.mediaItem?.videoSrc;
					}
				}
				setVideoUrl(videoUrl);
			}
		}		
		setCurrentIndex(newIndex);
	}

    return (
        <div className="directory-wrap event-detail-wrap">
            <div className="drawer-menu-collapse-wrap"></div>
			<div className="arrow-header" onClick={() => navigate('/news-tiles/')}>
				<img src={leftArrow} alt="left arrow" className="left-arrow" />
				<span>Back to Happenings</span>
			</div>
			<Navigation />
            <div className="events-page-wrap">
				<div className="inner-pages-alert news-detail-alert news-alert">
					<PortalAlert />
				</div>
                <div className="page-inner events-inner">
                    <div className="event-detail-body">
						<div className="event-detail-wrapper">							
							{news && news.media && news.media.length > 0 && (
								<Slider {...settings}>
									{processedItems.map((item, index) => {								
										if (item.mediaItem?.videoType === 1) {
											if (item.videoExists) {
												return (
													<div className="video-event-wrap" key={item.id}>
														<span className="desktop-video-payer video-player" onClick={() => openVideo(item.mediaItem, index)}></span>
														<img src={item.mediaItem?.previewsrc} alt="image" className="video-box" />
														{item.mediaItem?.type == 1 && (
															<div className="play-icon" onClick={() => openVideo(item.mediaItem, index)}>
																<img src={PlayIcon} alt="play" />
															</div>
														)}
													</div>
												);
											}
										} else {
											return (
												<div className="video-event-wrap" key={item.id}>
													<span className="desktop-video-payer video-player" onClick={() => openVideo(item.mediaItem, index)}></span>
													<img src={item.mediaItem?.previewsrc} alt="image" className="video-box" />
													{item.mediaItem?.type == 1 && (
														<div className="play-icon" onClick={() => openVideo(item.mediaItem, index)}>
															<img src={PlayIcon} alt="play" />
														</div>
													)}
												</div>
											);
										}
									})}
								</Slider>
							)}

							
								<div className={`event-detail-content-wrapper ${news && news.media && news.media.length > 0 ? 'slider-active':'slider-deactive'}`}>
									<div className="title-header">
										<p className="date-box">{formatDate(news.publishDate, "MMM dd, Y")}</p>
										<h1 className="event-detail-title">{news.title}</h1>
									</div>
									{news.content && DynamicHTMLRenderer(news.content)}
								</div>
							
						</div>
                    </div>
                </div>
			</div>
			{openModal && (
				<div className="video-modal">
					<div className="close-modal" onClick={openVideo}>
						<span className="material-symbols-rounded">
							close
						</span>
					</div>
					<div className="video-event-wrap news-video">
						{type == 0 && (<img src={previewImage} alt="video" className="video-box" />)}
						{type == 1 && (
							<iframe
								width="560"
								height="315"
								src={videoUrl}
								title=""					
								allow="autoplay;"
								frameBorder="0"
								allowFullScreen
							>
							</iframe>
						)}						
						
					</div>
					{processedItems.length > 1 && (
						<div className="video-arrows-wrap">
							<div className="arrow-modal prev" onClick={prevSlide}>
								<span className="arrow-wrap">
									<img src={prevArrow} alt="prev item" />
								</span>
							</div>
							<div className="arrow-modal next" onClick={nextSlide}>
								<span className="arrow-wrap">
									<img src={nextArrow} alt="next item" />
								</span>
							</div>
						</div>
					)}					
				</div>
			)}
        </div>
    );
}
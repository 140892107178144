import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdminError from '../../../components/admin/admin-error';
import AdminTextInput from '../../../components/admin/admin-text-input.js';
import MediaUploader from '../../../components/multipleMedia/MediaUploader.js';
import { eventItem } from './eventItem';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCompanies } from '../../../store/employee/actions';
import { getTypeEvent } from '../../../store/events/actions'; 
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import { submitEvent, getEventByIdEdit } from '../../../api/events';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import TimeDropdown from '../../../components/admin/events/time-dropdown';
import { RspvModal } from '../../../components/admin/events/rspv-modal';
import moment from 'moment';
import { useAdminMenuContext } from "../../../context/AdminMenuContext";


const ckEditorConfig = {
    toolbar: [
        'Bold',
        'Italic',
        'Link',
        'Unlink',
        'NumberedList',
        'BulletedList',
        'ImageUpload',
        'doclibrary'
    ],
    link: {
        decorators: {
            openInNewTab: {
                mode: 'manual',
                label: 'Open in a new tab',
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            },
        },
        addTargetToExternalLinks: true,  
        defaultProtocol: 'http://',  
        validate: function (url) {
           
            return /^(?:(?:https?|ftp):\/\/)?(?:\/)?(?:[^@\n]+@)?(?:www\.)?[^.]+\.[^.]+/.test(url);
        },
    },
    image: {
        resizeOptions: false,
        toolbar: []
    }
};

const FileRepository = {
    createUploadAdapter: (loader) => {
        return new UploadAdapter(loader);
    },
};
class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }
    upload() {
        return new Promise((resolve) => {
            const imageUrl = this.loader.data;
            resolve({ default: imageUrl });
        });
    }
}




export function AddEditEvent() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const defaultRspvObj = { id: 0, title: '', description: '', active: true }
    const [error, setError] = useState('');
    const [event, setEvent] = useState(eventItem);
    const { companiesList } = useSelector(state => state.employee);
    const { typeEventList } = useSelector(state => state.event);
    const [selectAll, setSelectAll] = useState(false);
    const [eventDatelog, setEventDate] = useState([{ eventDate: new Date(), startTime: '', endTime: '', error: '', startTimeError: '', eventStatDateTime: '', eventEndDateTime: '', eventDateinput: new Date() }]);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [selectTypeId, setSelectTypeId] = useState(0);
    const [eventId, setEventId] = useState(0);
    const [mediaItems, setMediaItems] = useState([]);
    const { eventBaseId, eventLogId } = useParams();
    const [showAddModal, setShowAddModal] = useState(false);
    const [rspvModel, setrspvModel] = useState(defaultRspvObj);
    const { getEventsRSVPs } = useSelector(state => state.event);
    const filteredItems = getEventsRSVPs.filter(item => item.numPlus1s);
    const sumOfValues = filteredItems.reduce((acc, item) => acc + item.numPlus1s, 0);
    const [activeDateValue, setActiveDateValue] = useState(event?.activeDate ? moment.utc(event?.activeDate).local().toDate() : new Date());
    console.log("sumOfValues", sumOfValues);
    const [editLink, setEditLink] = useAdminMenuContext();
    const [totalRsvp, setTotalRsvp] = useState(0);
    const RsvpTypes = {
        NO: 'no',
        YES: 'yes',
        ONE_PLUS: 'oneplus',
    };

    useEffect(() => {
        if (eventBaseId > 0) {
            dispatch(showLoading());
            getEventByIdEdit(eventBaseId).then((response) => {
                let eventResponse = response.data.data;
                setEvent(eventResponse);
                setTotalRsvp(eventResponse.rsvpTotalCount);
                if (companiesList.length == eventResponse.eventCompanies.length) {
                    setSelectAll(true);
                }
                setSelectTypeId(eventResponse?.eventTypeId || 0);
                setEventDate(eventResponse.eventLog);
                setSelectedOrganization(eventResponse.eventCompanies);
                setEventId(eventResponse.id);
                if (eventResponse?.media.length > 0) {
                    var getMediaItems = [];
                    eventResponse.media.forEach((item) => {
                        getMediaItems.push(item.mediaItem);
                    });
                    setMediaItems(getMediaItems);
                }
                setActiveDateValue(eventResponse?.activeDate ? moment.utc(eventResponse?.activeDate).local().toDate() : new Date());
                setEditLink(`/event-detail/${eventBaseId}/${eventLogId}`);
                console.log("editLink:", editLink);
                dispatch(hideLoading());
            });
        }
        else {
            setEditLink('');
        }

    }, []);


    


    const [validation, setValidation] = useState({
        eventTitle: true,
        eventOrganization: true
    });

    useEffect(() => {
        document.title = 'Events | CWP Employee Portal';
        dispatch(getCompanies());
        dispatch(getTypeEvent());
    }, []);

    const handleSelectChange = (e) => {
        const TypeId = e.target.value;
        if (TypeId) {
            setSelectTypeId(TypeId);
        }
    };

    const handleRspv = () => {
        setrspvModel(defaultRspvObj);
        setShowAddModal(true);
    }

    const handleAddMore = () => {
        const updatedEvents = [...eventDatelog];
        if (updatedEvents.filter(item => (item.error != '' || item.startTimeError != '') && (item.error != undefined || item.startTimeError != undefined)).length > 0) {
            return;
        }    
        setEventDate([...eventDatelog, { eventDate: new Date(), startTime: '', endTime: '', eventStatDateTime: '', eventEndDateTime: '', eventDateinput: new Date() }]);
    };

    const isValidDateFormat = (dateString) => {
        // Define a regular expression pattern for the desired format (mm/dd/yyyy)
        const dateFormatPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

        // Check if the dateString matches the pattern
        return dateFormatPattern.test(dateString);
    }

    const handleDateFormate = (date) => {
        const inputDate = date;
        let formattedDate = inputDate.replace(/\D/g, ''); // Remove all non-digit characters
        if (formattedDate.length > 2 && formattedDate.length <= 4) {
            formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2);
        } else if (formattedDate.length > 4) {
            formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2, 4) + '/' + formattedDate.slice(4, 8);
        }
        return formattedDate;
    };

    const handleStartDateChange = (event, index, field) => {
        if (event.type === "change") {   
            const updatedEvents = [...eventDatelog];
            const formattedDate = handleDateFormate(event.target.value);  
            updatedEvents[index].eventDateinput = formattedDate;
            if (isValidDateFormat(formattedDate)) {
                updatedEvents[index][field] = new Date(formattedDate);
            }

            setEventDate(updatedEvents);
        }
    };

    const handleAllCheckboxToggle = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        const updatedSelectedOrg = isChecked
            ? companiesList.map(company => ({ companyId: company.id }))
            : [];
        setSelectedOrganization(updatedSelectedOrg);
    };
    const updateDate = (index, field, value) => {
        const updatedEvents = [...eventDatelog];
        updatedEvents[index][field] = value;
        updatedEvents[index].error = '';
        updatedEvents[index].startTimeError = '';
        updatedEvents[index].eventDateinput = new Date(updatedEvents[index].eventDate);

        updatedEvents[index].eventDate = moment.utc(updatedEvents[index].eventDate).local().toDate();

        if (field === 'startTime' || field === "eventDate") {
            let eventStartDateTime = new Date(updatedEvents[index].eventDate);    
            let eventEndDateTime = new Date(updatedEvents[index].eventDate);

            let formattedEndTime;
            const format = "h:mm A";
            if (value) {
                const eventStartTime = field === "eventDate" ? moment(updatedEvents[index].startTime, format) : moment(value, format);
                if (eventStartTime.isValid()) {
                    const startTimeHour = eventStartTime.hours();
                    const startTimeMinute = eventStartTime.minutes();
                    eventStartDateTime.setHours(startTimeHour);
                    eventStartDateTime.setMinutes(startTimeMinute);
                    eventStartDateTime.setSeconds(0);

                    const eventEndTime = eventStartTime.clone().add(1, 'hour');
                    const endTimeHour = eventEndTime.hours();
                    const endTimeMinute = eventEndTime.minutes();
                    eventEndDateTime.setHours(endTimeHour);
                    eventEndDateTime.setMinutes(endTimeMinute);
                    eventEndDateTime.setSeconds(0);
                    formattedEndTime = eventEndTime.format('hh:mm A');

                }
                updatedEvents[index].eventStatDateTime = eventStartDateTime;
            

                if (!eventDatelog[index].endTime) {
                    setEventDate((prevEventDatelog) => {
                        const updatedEvents = [...prevEventDatelog];
                        updatedEvents[index].endTime = formattedEndTime;
                        return updatedEvents;
                    });
                    updatedEvents[index].eventEndDateTime = eventEndDateTime;
                }
            }
           
           

        }


        if (field === 'endTime' || field === "eventDate") {
            let eventEndDateTime = new Date(updatedEvents[index].eventDate);
            let eventStartDateTime = new Date(updatedEvents[index].eventDate);    

            let formattedStartTime;
            const format = "h:mm A";
            if (value) {
                const eventStartTime = field === "eventDate" ? moment(updatedEvents[index].endTime, format) : moment(value, format);
                if (eventStartTime.isValid()) {
                    const startTimeHour = eventStartTime.hours();
                    const startTimeMinute = eventStartTime.minutes();
                    eventEndDateTime.setHours(startTimeHour);
                    eventEndDateTime.setMinutes(startTimeMinute);
                    eventEndDateTime.setSeconds(0);

                    const startTime = eventStartTime.clone().subtract(1, 'hour');
                    const sTimeHour = startTime.hours();
                    const sMinute = startTime.minutes();
                    eventStartDateTime.setHours(sTimeHour);
                    eventStartDateTime.setMinutes(sMinute);
                    eventStartDateTime.setSeconds(0);
                    formattedStartTime = startTime.format('hh:mm A');

                    
                }
                updatedEvents[index].eventEndDateTime = eventEndDateTime;
               

                if (!eventDatelog[index].startTime) {
                    setEventDate((prevEventDatelog) => {
                        const updatedEvents = [...prevEventDatelog];
                        updatedEvents[index].startTime = formattedStartTime;
                        return updatedEvents;
                    });
                    updatedEvents[index].eventStatDateTime = eventStartDateTime;
                }
            }
            
        }


        if (field === 'startTime' || field === 'endTime') {
            let startTime = updatedEvents[index].startTime;
            let endTime = updatedEvents[index].endTime;
            if (value) {
                const timeRegex = /^(\d{1,2})(?::?(\d{1,2}))?(\s?(?:A|P|AM|PM))?$/i;
                const match = value.match(timeRegex);

                if (match != null) {
                    let hours = parseInt(match[1], 10);
                    let minutes = match[2] ? parseInt(match[2], 10) : 0;
                    let period = match[3] ? match[3].toLowerCase().trim() : '';

                    if (period === 'pm' && hours < 12) {
                        hours += 12;
                    } else if (period === 'am' && hours === 12) {
                        hours = 0;
                    }


                    if (field === 'startTime' && endTime !== '' && endTime != undefined) {
                        const matchEndTime = endTime.match(timeRegex);
                        if (matchEndTime != null) {
                            let hoursEndTime = parseInt(matchEndTime[1], 10);
                            let minutesEndTime = matchEndTime[2] ? parseInt(matchEndTime[2], 10) : 0;
                            let periodEndTime = matchEndTime[3] ? matchEndTime[3].toLowerCase().trim() : '';

                            if (periodEndTime === 'pm' && hoursEndTime < 12) {
                                hoursEndTime += 12;
                            } else if (periodEndTime === 'am' && hoursEndTime === 12) {
                                hoursEndTime = 0;
                            }

                            if ((hours == hoursEndTime && minutes > minutesEndTime) || (hours > hoursEndTime)) {
                                updatedEvents[index].error = 'End time can not be less than or equal to start time.';
                            }
                        }

                    }
                    else if (field === 'endTime' && startTime !== '' && startTime != undefined) {
                        const matchStartTime = startTime.match(timeRegex);
                        if (matchStartTime != null) {
                            let hoursStartTime = parseInt(matchStartTime[1], 10);
                            let minutesStartTime = matchStartTime[2] ? parseInt(matchStartTime[2], 10) : 0;
                            let periodStartTime = matchStartTime[3] ? matchStartTime[3].toLowerCase().trim() : '';

                            if (periodStartTime === 'pm' && hoursStartTime < 12) {
                                hoursStartTime += 12;
                            } else if (periodStartTime === 'am' && hoursStartTime === 12) {
                                hoursStartTime = 0;
                            }

                            if ((hoursStartTime == hours && minutesStartTime >= minutes) || hoursStartTime > hours) {
                                updatedEvents[index].error = 'End time can not be less than or equal to start time.';
                            }
                        }
                    }
                }
            }
       
        }

        const previousDate = updatedEvents.filter((event, i) => i !== index && i < index);
        if (previousDate.length > 0 && updatedEvents[index].eventStatDateTime !== '' && updatedEvents[index].eventStatDateTime !== undefined) {
            let errorEncountered = false;
            previousDate.forEach(date => {
                let checkDateTime = date.eventEndDateTime;
                if (date.eventEndDateTime === undefined) {
                    const eventdateTime = moment.utc(date.eventDate).local().toDate();
                    const dateMoment = moment(eventdateTime);
                    dateMoment.set({ hour: date.endTimeHour, minute: date.endTimeMinute, second: 0 });
                    checkDateTime = dateMoment.toDate();
                }

                let checkStatDateTime = updatedEvents[index].eventStatDateTime;               
                if (updatedEvents[index].eventStatDateTime === undefined) {
                    const eventdateTime = moment.utc(updatedEvents[index].eventDate).local().toDate();
                    const dateMoment = moment(eventdateTime);
                    dateMoment.set({ hour: date.endTimeHour, minute: date.endTimeMinute, second: 0 });
                    checkStatDateTime = dateMoment.toDate();
                }

                if (moment(checkDateTime).isAfter(checkStatDateTime)) {
                    updatedEvents[index].startTimeError = 'Date time can not be less than or equal to previous date time';
                    errorEncountered = true;
                    return; 
                }
            });     

            if (errorEncountered) {
                updatedEvents[index].startTimeError = 'Date time can not be less than or equal to previous date time';
            }
        }

        const nextDates = updatedEvents.filter((event, i) => i !== index && i > index);
        if (nextDates.length > 0 && updatedEvents[index].eventEndDateTime !== '' && updatedEvents[index].eventEndDateTime !== undefined) {
            let errorEncountered = false;
            nextDates.forEach(date => {
                let checkDateTime = date.eventStatDateTime;
                if (date.eventStatDateTime === undefined) {
                    const eventdateTime = moment.utc(date.eventDate).local().toDate();
                    const dateMoment = moment(eventdateTime);
                    dateMoment.set({ hour: date.startTimeHour, minute: date.startTimeMinute, second: 0 });
                    checkDateTime = dateMoment.toDate();
                }

                let checkStatDateTime = updatedEvents[index].eventEndDateTime;
                if (updatedEvents[index].eventEndDateTime === undefined) {
                    const eventdateTime = moment.utc(updatedEvents[index].eventDate).local().toDate();
                    const dateMoment = moment(eventdateTime);
                    dateMoment.set({ hour: date.endTimeHour, minute: date.endTimeMinute, second: 0 });
                    checkStatDateTime = dateMoment.toDate();
                }

                if (moment(checkDateTime).isBefore(checkStatDateTime)) {
                    updatedEvents[index].error = 'Date time can not be greater than or equal to next date time';
                    errorEncountered = true;
                    return;
                }
            });

            if (errorEncountered) {
                updatedEvents[index].error = 'Date time can not be greater than or equal to next date time';
            }
        }
 
        setEventDate(updatedEvents);

    };
    const deleteDateEvent = (index) => {
        const updatedEvents = [...eventDatelog];
        updatedEvents.splice(index, 1);
        setEventDate(updatedEvents);
    };
    const handleOrganizationCompanyToggle = (e, companyId) => {
        const checked = e.target.checked;
        let updatedOrganization;

        if (checked) {
            updatedOrganization = [...selectedOrganization, { companyId }];
            if (companiesList.length == updatedOrganization.length) {
                setSelectAll(true);
            }
        } else {
            updatedOrganization = selectedOrganization.filter(item => item.companyId !== companyId);
            if (selectAll) {
                setSelectAll(false);
            }
        }

        setSelectedOrganization(updatedOrganization);
    }

    const handleEventSave = () => {
        dispatch(showLoading());
        event.eventLog = eventDatelog;

        event.eventCompanies = selectedOrganization;
        event.eventTypeId = selectTypeId;
        if (selectTypeId == 0 && typeEventList != undefined && typeEventList != null && typeEventList.length > 0) {
            event.eventTypeId = typeEventList[0].id;
        }


        var isValid = true;

        var validate = {
            eventTitle: true,
            titleError: '',
            eventOrganization: true,
            organizationError: ''

        };
        if (event == null || event.title == null || event.title == "") {
            validate.eventTitle = false;
            validate.titleError = "Title is required";
            isValid = false;
        }
        if (event == null || event.eventCompanies == null || event.eventCompanies == "") {
            validate.eventOrganization = false;
            validate.organizationError = "At least select one organization";
            isValid = false;
        }

        setValidation({ ...validation, ...validate });



        const isValidTime = eventDatelog.every((item, index) => {
                      
            if (item.startTime != undefined && item.startTime != null && item.startTime != '' && (item.endTime === '' || item.endTime == null || item.endTime == undefined)) {
                setEventDate((prevEventDatelog) => {
                    const updatedEvents = [...prevEventDatelog];
                    updatedEvents[index].error = 'End time cannot be empty.';
                    return updatedEvents;
                });
                return false;
                }
             else if (item.endTime != undefined && item.endTime != null && item.endTime != '' && (item.startTime === '' || item.startTime == null || item.startTime == undefined)) {
                    setEventDate((prevEventDatelog) => {
                        const updatedEvents = [...prevEventDatelog];
                        updatedEvents[index].startTimeError = 'Start time can not be empty.';
                        return updatedEvents;
                    });
                    return false;
             }
             else if (item?.error != undefined && item?.error != null && item?.error != '') {
                 setEventDate((prevEventDatelog) => {
                     const updatedEvents = [...prevEventDatelog];
                     updatedEvents[index].error = 'End time can not be less than or equal to start time.';
                     return updatedEvents;
                 });
                 return false;
             }
                else if ((item.error !== undefined && item.startTimeError !== undefined) && (item.error !== '' || item.startTimeError !== '')) {
                return false;
            }
  
            const eventDate = item.id !== undefined ? moment.utc(item.eventDate).local().toDate() : item.eventDate;
            let eventDateTime = new Date(eventDate);  
            const format = "h:mm A";
            if (item.startTime) {
                const eventStartTime = moment(item.startTime, format);
                if (eventStartTime.isValid()) {
                    const startTimeHour = eventStartTime.hours();
                    const startTimeMinute = eventStartTime.minutes();
                    eventDateTime.setHours(startTimeHour);
                    eventDateTime.setMinutes(startTimeMinute);
                }
            }
            eventDateTime.setSeconds(0);
            item.eventDate = eventDateTime;

            return true;
        });

        if (!isValidTime) {
            dispatch(hideLoading());
            return;
        }

        if (isValid == false) {
            dispatch(hideLoading());
            return;
        }

        var eventUpdateMedia = [];

        mediaItems.forEach((item) => {
            var eventMediaObj = {
                mediaId: item.id
            };
            eventUpdateMedia.push(eventMediaObj);
        });
        event.media = eventUpdateMedia;  

        submitEvent(event)
            .then((response) => {
                if (response.data.success == false) {
                    dispatch(hideLoading());
                    setError(response.data.message);
                    return;
                }
                if (response.data.success) {
                    dispatch(hideLoading());
                    navigate('/siteadmin/events');
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                if (error.response.data && error.response.data.message) {
                    setError(error.response.data.message);
                } else {
                    setError(error.response.responseText);
                }
            });
    };

    const handleMediaUpdates = (mediaItems) => {
        console.log("updating final media Items....", mediaItems);
        setMediaItems(mediaItems);
    };

    const handlePaste = (event, editor) => {
        const pastedData = event.clipboardData.getData('text/plain');
        const currentData = editor.getData(); 
        const newData = currentData + pastedData;

        setEvent((prevEvent) => ({ ...prevEvent, content: newData }));
        setIsInitialLoad(false);
    };  
    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin/events'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <RspvModal
                show={showAddModal}
                onClose={() => setShowAddModal(false)}
                modelPosting={rspvModel}
                eventId={eventId}
            />
            <div className="admin-page-content">
                <h1 className="admin-page-heading ">Add/Edit Events</h1>
                <AdminError
                    message={error}
                    onClose={() => {
                        setError('');
                    }}
                ></AdminError>
                <p className="admin-section-small-headline events-subtitle">Title & Images/Videos</p>
                <div className="admin-section admin-section-narrow admin-disp-flex admin-wid-100">
                    <div className="admin-left-section">
                        <div className="admin-section-row">
                            <div className="admin-section-cell wide">
                                <AdminTextInput
                                    label="Event Title"
                                    id="title-Event"
                                    value={event?.title ?? ''}
                                    isRequired={true}
                                    onChange={(value) => {
                                        setEvent({ ...event, title: value });
                                        setValidation({ ...validation, eventTitle: value !== '' });
                                    }}
                                    validated={validation.eventTitle}
                                    requiredText={validation.titleError}
                                />
                            </div>
                            <div className="admin-section-cell wide">
                                <label className="admin-checkbox-label hr-event-checkbox">
                                    <span className="admin-inline-label-text">Show as a HR Event</span>
                                    <input
                                        className="admin-inline-checkbox"
                                        type="checkbox"
                                        checked={event && event.hrEvent}
                                        onChange={(e) => {
                                            setEvent({ ...event, hrEvent: e.target.checked });
                                        }}
                                    />

                                </label>
                            </div>
                        </div>


                        <div className="admin-section-row">
                            <div className="admin-section-cell full">
                                <label className="admin-default-label">
                                    Organization<span>*</span>
                                </label>
                                {!validation.eventOrganization && (
                                    <p className="input-validation-error"><span className="validation-error-message">{validation.organizationError}</span></p>
                                )}
                                <div className="admin-column-wrap auto-columns addevents-auto-checkbox">
                                    <label className="admin-checkbox-label inline">
                                        <input
                                            className="admin-inline-checkbox"
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleAllCheckboxToggle}
                                        ></input>
                                        All
                                    </label>
                                    {companiesList && companiesList.map(company => (
                                        <label
                                            className="admin-checkbox-label inline"
                                            key={company?.id}>
                                            <input
                                                className="admin-inline-checkbox"
                                                type="checkbox"
                                                checked={selectedOrganization.some(x => x.companyId === company.id)}
                                                onChange={(e) => {
                                                    handleOrganizationCompanyToggle(e, company.id);
                                                    setValidation({ ...validation, eventOrganization: e != '' });
                                                }}></input>
                                            {company.abbreviatedName}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div>
                            {eventDatelog && eventDatelog.map((item, index) => {                              
                                const eventDate = item.id !== undefined ? moment.utc(item.eventDate).local().toDate() : item.eventDate;
                                return (
                                    <div key={index} className="admin-section-row">
                                        <div className="admin-section-cell">
                                            <label className="admin-default-label">Date(s) of Event</label>
                                            <DatePicker
                                                className="admin-default-input-text date-calendar-icon"
                                                selected={eventDate}
                                                placeholder="mm/dd/yyyy"
                                                autoComplete="off"
                                                onChange={(date) => updateDate(index, 'eventDate', date)}
                                                showTimeSelect={false}
                                                minDate={new Date()}
                                                maxDate={new Date(2099, 11, 31)}                                     
                                                onChangeRaw={(event) => handleStartDateChange(event, index, eventDate)}
                                                value={item.eventDateinput ? item.eventDateinput : new Date()}
                                            />
                                        </div>
                                        <div className="admin-section-cell">
                                            <label className="admin-default-label">Start Time</label>
                                            <TimeDropdown selectedValue={item.startTime} handleTimeSelection={updateDate} indexVal={index} inputFiled="startTime" />
                                            {item.startTimeError && (
                                                <p className="input-validation-error">
                                                    <span className="validation-error-message">{item.startTimeError}</span>
                                                </p>
                                            )}
                                        </div>
                                        <div className="admin-section-cell">
                                            <label className="admin-default-label">End Time</label>
                                            <TimeDropdown selectedValue={item.endTime} handleTimeSelection={updateDate} indexVal={index} inputFiled="endTime" />
                                            {item.error && (
                                                <p className="input-validation-error">
                                                    <span className="validation-error-message">{item.error}</span>
                                                </p>
                                            )}
                                        </div>
                                        <div className="admin-section-cell">
                                            <label className="admin-default-label"></label>
                                            {eventDatelog.length > 1 && (
                                                <button className="admin-delete-button" onClick={() => deleteDateEvent(index)}>
                                                    X
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                )})}

                        </div>
                        <div className="admin-section-row">
                            <div className="admin-button-wrap">
                                <button className="admin-button" onClick={handleAddMore}>Add More Date/Times</button>
                            </div>
                        </div>
                        
                        <div className="admin-section-row">
                            <div className="admin-section-cell">
                                <label className="admin-radio-label inline">
                                    <input className="admin-inline-checkbox" type="radio" id="active" name="active" value={false} checked={event && event.isActive === false} onChange={() => setEvent({ ...event, isActive: false })} />
                                    Active Events
                                </label>
                            </div>
                            <div className="admin-section-cell">
                                <label className="admin-radio-label inline label-nowrap">
                                    <input className="admin-inline-checkbox" type="radio" id="inactive" name="active" value={true} checked={event && event.isActive === true} onChange={() => setEvent({ ...event, isActive: true })} />
                                    Don&apos;t show event until
                                </label>
                            </div>
                            {event && event.isActive === true && (              
                                <div className="admin-section-cell">
                                    <DatePicker
                                        id="active-event-date"
                                        className="admin-default-input-text date-calendar-icon"                                  
                                        selected={event?.activeDate ? moment.utc(event?.activeDate).local().toDate() : new Date()}
                                        placeholder="mm/dd/yyyy"
                                        minDate={new Date()}
                                        maxDate={new Date(2099, 11, 31)}
                                        value={activeDateValue}                                   
                                        onChange={(date) => {                                       
                                            setEvent({ ...event, activeDate: date });
                                        }}
                                    />
                                </div>
                            )}                            
                        </div>

                        <label className="admin-default-label">Type of Events</label>
                        <div className="admin-section-row mt-0">
                            <div className="admin-section-cell">
                                <select
                                    className="admin-default-select"
                                    onChange={handleSelectChange}
                                    value={selectTypeId}
                                >                             
                                    {typeEventList && typeEventList.length > 0 &&
                                        typeEventList.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                </select>
                            </div>

                            <div className="admin-section-cell wide">
                                <div>
                                    <label className="admin-default-label">Is it an RSVP Event?</label>
                                    <div className="admin-column-wrap inline-tags">
                                        <label className="admin-radio-label inline">
                                            <input className="admin-inline-checkbox" type="radio" id={RsvpTypes.NO} name="rsvp" value={RsvpTypes.NO} checked={event.rsvpType === RsvpTypes.NO} onChange={() => setEvent({ ...event, rsvpType: RsvpTypes.NO })} />
                                            No
                                        </label>
                                        <label className="admin-radio-label inline">
                                            <input className="admin-inline-checkbox" type="radio" id={RsvpTypes.YES} name="rsvp" value={RsvpTypes.YES} checked={event.rsvpType === RsvpTypes.YES} onChange={() => setEvent({ ...event, rsvpType: RsvpTypes.YES })} />
                                            Yes
                                        </label>
                                        <label className="admin-radio-label inline">
                                            <input className="admin-inline-checkbox" type="radio" id={RsvpTypes.ONE_PLUS} name="rsvp" value={RsvpTypes.ONE_PLUS} checked={event.rsvpType === RsvpTypes.ONE_PLUS} onChange={() => setEvent({ ...event, rsvpType: RsvpTypes.ONE_PLUS })} />
                                            Yes with +1s
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admin-section-row">
                            <AdminTextInput
                                label="Event Location"
                                id="first-name"
                                value={event && event.eventLocation ? event.eventLocation : ''}
                                onChange={(value) => {
                                    setEvent({ ...event, eventLocation: value });
                                }}
                            />
                        </div>

                        <div className="admin-section-row circle">
                            <div className="admin-section-cell full">
                                <p className="admin-section-small-headline">Image/Video</p>
                                <div className="admin-media-upload-wrapper">
                                    <MediaUploader
                                        isPlaceHolder={false}
                                        requireAltText={false}
                                        imageOnly={false}
                                        videoOnly={false}
                                        forceCrop={false}
                                        allowGallery={true}
                                        mediaItems={mediaItems}
                                        saveMediaItems={handleMediaUpdates}
                                        allowSorting={true}
                                        sortInTable="EventsMedia"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="admin-section-row">
                            <div
                                className={
                                    'admin-section-cell extra-wide full-width'
                                }
                            >
                                <label className="admin-default-label" htmlFor="short-desc">
                                    Event Content
                                </label>
                                <CKEditor
                                    editor={Editor}
                                    config={ckEditorConfig}
                                    data={event && event.content || ''}
                                    onReady={(editor) => {
                                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
                                            FileRepository.createUploadAdapter(loader);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setEvent((prevEvent) => ({ ...prevEvent, content: data }));
                                        if (!isInitialLoad) {
                                            console.log("data", data);                                            
                                        }
                                        setIsInitialLoad(false);
                                    }}
                                    onPaste={handlePaste} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className="admin-section-right">
                        <div onClick={() => handleRspv()} className="total-rsvp  total-rsvp-icon">
                            <h2 className="rsvp-title"> {totalRsvp && totalRsvp > 0 ? totalRsvp : 0}</h2>
                            <span className="admin-button-text rsvp-subtitle ">Total RSVPs</span>
                            {/*                            <img className="" src={ArrowIcon} />*/}

                        </div>
                    </div>
                </div>

                <div className="admin-floating-button-wrap">
                    <button
                        className="admin-floating-button"
                        onClick={() =>
                            navigate('/siteadmin/events')
                        }
                    >
                        Cancel
                    </button>
                    <button
                        className="admin-floating-button power"
                        onClick={handleEventSave}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
            {/*< />*/}
        </div>
    );
}
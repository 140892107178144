import React, { useState, useRef, useEffect } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import ClickOutsideWrapper from '../../../hooks/click-outside-wrapper';
import PropTypes from 'prop-types';
import debounce from '../../../helpers/debounce';


function formatTimeTo12HourFormat(date) {
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 || 12;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
	return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

function generateTimeInterval() {
	const options = [];
	const startTime = new Date();
	startTime.setHours(0, 0, 0, 0);

	const endTime = new Date();
	endTime.setHours(23, 45, 0, 0);	

	while (startTime <= endTime) {
		const timeString = formatTimeTo12HourFormat(startTime);
		options.push(timeString);

		startTime.setMinutes(startTime.getMinutes() + 15);
	}

	return options;
}


const TimeDropdown = ({ selectedValue, handleTimeSelection, indexVal, inputFiled }) => {
	const inputRef = useRef(null);
	const scrollbarsRef = useRef();
	const scrollOptionRef = useRef();

    const [selectOpen, setSelectOpen] = useState(false);
	const optionValues = generateTimeInterval();
	const [focusedIndex, setFocusedIndex] = useState(-1);
	const [searchInput, setSearchInput] = useState('');
	const [inValidTime, setInValidTime] = useState(false);

	const [selectedOptionValue, setSelectedOptionValue] = useState(selectedValue);

	useEffect(() => {
		setSelectedOptionValue(selectedValue);		
	}, [selectedValue, inputFiled]);


	const formatInput = (rawInput) => {
		// Merged regular expression to match various time formats
		const timeRegex = /^(\d{1,2})(?::?(\d{1,2}))?(\s?(?:A|P|AM|PM))?$/i;	

		// Check if the input matches the format
		const match = rawInput.match(timeRegex);

		if (!match) {
			return null;
		}

		let hours = parseInt(match[1], 10);
		let minutes = match[2] ? parseInt(match[2], 10) : 0;
		let period = match[3] ? match[3]?.toLowerCase().trim() : '';		

		if (hours > 24) {
			return null;
		}

		if (minutes >= 60) {
			return null;
		}

		if ((period === 'pm' || period === 'p' || period === '') && hours >= 12) {
			period = 'pm';
		} else if ((period === 'am' || period === 'a' || period === '') && hours < 12) {
			period = 'am';
		}

		if (hours > 12) {
			hours -= 12;
		} else if (hours === 0) {
			hours = 12;
		}		

		// Pad single-digit hours and minutes with leading zeros
		const formattedHours = hours.toString();
		const formattedMinutes = minutes.toString().padStart(2, '0');

		let findItemIndex = hours;

		if (minutes > 0) {
			findItemIndex = `${hours}:${formattedMinutes}`;
		}

		if (period == "pm" || period == "am") {
			findItemIndex = `${hours}:${formattedMinutes} ${period.toUpperCase()}`;
		}
		const matchIndex = optionValues && optionValues.findIndex(option => option.startsWith(findItemIndex.toString()));
		setFocusedIndex(matchIndex);


		return `${formattedHours}:${formattedMinutes} ${period.toUpperCase()}`;
	};

	const handleInputChange = (e) => {		
		let searchTime = '';
		setSelectedOptionValue(e.target.value);	

		const formatted = formatInput(e.target.value.trim());

		if (e.target.value != '' && e.target.value != ' ' && e.target.value !== null) {
			const matchingOption = optionValues.find(
				(option) => option.toString().startsWith(e.target.value.toUpperCase())
			);

			if (matchingOption) {
				searchTime = matchingOption;
			}
		}

		if (formatted != null && searchTime == '') {
			searchTime = formatted;
		}

		if (formatted == null && searchTime == '' && e.target.value != '' && e.target.value != ' ' && e.target.value !== null) {			
			setInValidTime(true);
		} else {
			setInValidTime(false);			
		}

		if (searchTime === '') {			
			debounce(() => {
				handleTimeSelection(indexVal, inputFiled, '');
			});
		}

		setSearchInput(searchTime);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' || event.key === 'Tab') {
			setSelectOpen(false);
			if (searchInput !== '') {
				setSelectedOptionValue(searchInput);
				debounce(() => {
					handleTimeSelection(indexVal, inputFiled, searchInput);
				});
			} else if (inValidTime) {
				setSelectedOptionValue('');
				debounce(() => {
					handleTimeSelection(indexVal, inputFiled, '');
				});
			}
		}

		if (inValidTime && event.key === 'Tab') {
			setSelectedOptionValue('');	
			setInValidTime(false);
			debounce(() => {
				handleTimeSelection(indexVal, inputFiled, '');
			});			
		}
	};

	const handleBlur = () => {
		if (inValidTime) {
			setSelectedOptionValue('');
			setInValidTime(false);			
			debounce(() => {
				handleTimeSelection(indexVal, inputFiled, '');
			});	
		} else if (selectedOptionValue == '') {
			debounce(() => {
				handleTimeSelection(indexVal, inputFiled, '');
			});				
		}
	};


	useEffect(() => {
		const matchIndex = optionValues && optionValues.findIndex(option => option.toString().startsWith(selectedOptionValue));
		if (matchIndex > -1) {
			setFocusedIndex(matchIndex);
		} else {
			const matchIndex1 = optionValues && optionValues.findIndex(option => option.toString().startsWith(selectedOptionValue.substring(0, 2)));
			if (matchIndex1 > -1) {
				setFocusedIndex(matchIndex1);
			}
		}
	}, [optionValues]);

	useEffect(() => {
		// Scroll to the selected option when the component loads
		if (scrollbarsRef.current && selectOpen) {
			const optionHeight = scrollOptionRef.current ? scrollOptionRef.current.offsetHeight : 26.5;
			scrollbarsRef.current.scrollTop = focusedIndex * optionHeight - 60;
		}
	}, [focusedIndex, selectOpen]);


	

	return (
		<div className={`custom-select-wrap${selectOpen ? ' custom-select-open' : ''}`}>			
			<input
				type="text"
				className={`custom-select-trigger ${inValidTime ? 'error' : ''}`}
				ref={inputRef}
				placeholder="hh:mm"
				value={selectedOptionValue}
				onClick={(e) => {
					e.preventDefault();
					setSelectOpen(!selectOpen);
					inputRef.current.select();
				}}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				onBlur={handleBlur}		
			/>
			{selectOpen && (
				<ClickOutsideWrapper onClose={() => {
					setSelectOpen(false);
					if (searchInput !== '') {
						setSelectedOptionValue(searchInput);
						debounce(() => {
							handleTimeSelection(indexVal, inputFiled, searchInput);
						});						
					}				
				}}>
					<div className="custom-select-dropdown">
						<div className="custom-select-scroll scrollbar-inner">
							<Scrollbar translateContentSizesToHolder ref={scrollbarsRef}>
								{optionValues.map((value, index) => (
									<a
										className={`custom-select-scroll-option ${focusedIndex === index ? 'focused' : ''}`}
										key={value}
										onClick={(e) => {
											e.preventDefault();											
											setSelectedOptionValue(value);
											setSelectOpen(false);
											setInValidTime(false);
											debounce(() => {
												handleTimeSelection(indexVal, inputFiled, value);
											});
										}}
										ref={scrollOptionRef}
									>
										{value}
									</a>
								))}
							</Scrollbar>
						</div>						
					</div>
				</ClickOutsideWrapper>
			)}
		</div>
	);
    
};

TimeDropdown.defaultProps = {
	selectedValue: ''
}

TimeDropdown.propTypes = {
	selectedValue: PropTypes.string,
	handleTimeSelection: PropTypes.func,
	indexVal: PropTypes.number,
	inputFiled: PropTypes.string,
	isDisabled: PropTypes.bool
}

export default TimeDropdown;

import { parsePhoneNumber, AsYouType } from 'libphonenumber-js'; // https://github.com/catamphetamine/libphonenumber-js
import { format, parseISO, differenceInYears, differenceInMonths } from "date-fns";
import axios from 'axios';

export const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});

export const formatUSD = (value) => {
    if (isNaN(value)) {
        return "$0";
    }
    let formatted = USDollar.format(value);
    return formatted;
}

export const formatPhoneNumber = (value) => {
  try {
    if (value === "") {
      return "";
    }
    const phoneNumber = parsePhoneNumber(value, 'US');
    if (phoneNumber) {
      return phoneNumber.formatNational();
    } else {
      return "";
    }
  }
  catch {
    return "";
  }
}

export const formatPhoneNumberUri = (value) => {
    if (value === "") {
        return "";
    }
    const phoneNumber = parsePhoneNumber(value, 'US');
    if (phoneNumber) {
        return phoneNumber.getURI();
    } else {
        return "";
    }
}

export const formatPhoneNumberAsYouType = (value) => {
  var tempPhone = value;
  tempPhone = formatPhoneFromNonIntegers(tempPhone);
  var formattedPhoneNumber = new AsYouType('US').input(tempPhone); 
  
  if (formattedPhoneNumber[formattedPhoneNumber.length - 1] === ")")
  { 
    formattedPhoneNumber = formattedPhoneNumber.slice(0, -1);
  }

    return formattedPhoneNumber;
}

export const formatDate = (date, formatOfDate) => {
    try {
        if (!formatOfDate) formatOfDate = "MM/dd/yyyy";
        const dateParsed = parseISO(date);
        const formatedDate = format(dateParsed, formatOfDate);
        return formatedDate;

    } catch {
        return "";
    }
};

export const formatPhoneFromNonIntegers = (phoneNumber) => { 
  if(phoneNumber)
    phoneNumber = phoneNumber.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").replace(".", "").replace("+", "");
  else
    phoneNumber = "";

return phoneNumber;
}

const convertToUTCDateOnly = (date) => {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
};

export const calculateDateDiff = (date) => {
    let yearsDiff = 0;
    let monthsDiff = 0;
    if (date === undefined || date === null || date === '') return { yearsDiff, monthsDiff };

    const today = new Date();
    const utcToday = convertToUTCDateOnly(today);
    const formattedToday = formatDate(utcToday.toISOString().split('T')[0], "yyyy-MM-dd");

    const localDateTime = new Date(date);
    const formattedLocalDateTime = formatDate(localDateTime.toISOString(), "yyyy-MM-dd");

     yearsDiff = differenceInYears(new Date(formattedToday), new Date(formattedLocalDateTime));
     monthsDiff = differenceInMonths(new Date(formattedToday), new Date(formattedLocalDateTime)) % 12;

    return { yearsDiff, monthsDiff };
};

export const formatDatemonth = (dateString) => {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
}; 
export const formatLongDateWithOrdinal = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();
    const suffix = getDaySuffix(day);

    return `${month} ${day}${suffix}, ${year}`;
};


export const getWeekLine = (dateString) => {
    const date = parseISO(dateString);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const day = date.getDate();
    const suffix = getDaySuffix(day);
    return `${month} ${day}${suffix}`;
};

const getDaySuffix = (day) => {
    if ((day >= 11 && day <= 13) || (day % 10 === 0) || (day % 10 >= 4 && day % 10 <= 9)) {
        return 'th';
    }
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
};

export const formatTime = (timeString) => {
    if (!timeString) return '';
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":");
 
    let hour = parseInt(hours, 10);
    let minute = parseInt(minutes, 10);
 
    if (period === "PM" && hour !== 12) {
        hour += 12;
    }
     
    const formattedMinute = minute < 10 ? '0' + minute : minute;
 
    return `${hour}:${formattedMinute}${period?.toLowerCase()}`;
};


export const getMonthshortName = (newMonthName) => {
    let monthShortName;
    switch (newMonthName) {
        case 'January':
            monthShortName = 'Jan';
            break;
        case 'February':
        case 'Febuary':
            monthShortName = 'Feb';
            break;
        case 'March':
            monthShortName = 'Mar';
            break;
        case 'April':
            monthShortName = 'Apr';
            break;
        case 'May':
            monthShortName = 'May';
            break;
        case 'June':
            monthShortName = 'Jun';
            break;
        case 'July':
            monthShortName = 'Jul';
            break;
        case 'August':
            monthShortName = 'Aug';
            break;
        case 'September':
            monthShortName = 'Sep';
            break;
        case 'October':
            monthShortName = 'Oct';
            break;
        case 'November':
            monthShortName = 'Nov';
            break;
        case 'December':
            monthShortName = 'Dec';
            break;
        default:
            monthShortName = newMonthName;
    }

    return monthShortName;
}


export const findNearestIndex = (array, number) => {
    let minDiff = Infinity;
    let nearestIndex = null;

    array.forEach((element, index) => {
        const diff = Math.abs(element - number);

        if (diff < minDiff) {
            minDiff = diff;
            nearestIndex = index;
        }
    });

    return nearestIndex;
}



export const processUrl = (sourceData) => {
    let media = {
        id: null,
        urlval: null,
        type: null,
        success: false
    };
    if (sourceData.match(/^(http(s?)?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|.*[?&]v=)|youtu\.be\/)/)) {
        let youtube_id = '';
        if (sourceData.match('embed')) {
            youtube_id = sourceData.split(/embed\//)[1].split('"')[0];
        } else {
            youtube_id = sourceData.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
        }
        media.type = "youtube";
        media.id = youtube_id;
        media.success = true;
    }

    else if (sourceData.match('http(s?)://(player.)?vimeo\\.com')) {
        var split = sourceData.split(/video\/|http(s?):\/\/vimeo\.com\//);
        let vimeo_id = split[split.length - 1];
        if (vimeo_id.indexOf('/') > -1) {
            media.id = vimeo_id.split('/')[0];
            media.urlval = 'h=' + vimeo_id.split('/')[1];
        } else {
            media.id = vimeo_id;
        }
        media.type = "vimeo";
        media.success = true;
    }
    else if (sourceData.match(/^https?:\/\/(www\.)?(imstagingstorage|blob\.net)/)) {
        media.type = "htmlVideo";
        media.success = true;
    }
    else if (sourceData.match(/^https?:\/\/(www\.)?(imlivestorage|blob\.net)/)) {
        media.type = "htmlVideo";
        media.success = true;
    }

    return media;
};



export const isVimeoVideoExists = async (id, token) => {    
    if (token === undefined || token === null || token == '') {
        token = 'e081ff4fdcee59317b8d0585865ce8f7';
    }
    const url = `https://api.vimeo.com/videos/${id}`;
    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 200)
            return true;
        else return false;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return false;
        }
        return false;
    }
};

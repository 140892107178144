import React, { useState, useEffect } from 'react';
import './eventdetail.css';
import leftArrow from '../../assets/arrow_back.svg';
import PlayIcon from '../../assets/play_arrow.png';
import Calendar from '../../assets/icon/calendar_today.svg';
import Location from '../../assets/icon/location_on.svg';
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { getTypeEvent } from '../../store/events/actions';
import { getEventDetailByBaseId, getCalendarIcsFile } from '../../api/events';
import { useParams, useNavigate } from 'react-router-dom'
import { getEvenRsvptUpdated } from '../../store/events/actions';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import { formatLongDateWithOrdinal, processUrl, isVimeoVideoExists } from '../../helpers/formatterHelper';
import Navigation from '../../components/navigation/Navigation';
import { formatDate } from '../../components/common/DateTimeFormatter';
import nextArrow from '../../assets/arrow_next.svg';
import prevArrow from '../../assets/arrow_prev.svg';
import { RsvpConfirmModal } from '../../components/common/RsvpConfirmModel';
import { getEmployeesCompanyList } from '../../api/events';
import PortalAlert from '../../components/portalAlert/portalAlert';
import { getVimeoToken } from '../../api/docCenterApi';
import moment from 'moment';

export default function EventDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showRsvpConfirmModal, setShowRsvpConfirmModal] = useState(false);
    const { siteUserId } = useSelector(state => state.auth);
    const [openModal, setOpenModal] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [type, setType] = useState(0);
    const [videoUrl, setVideoUrl] = useState("");
    const [event, setEvent] = useState(null);
    const { baseId } = useParams();
    const [selectedAttendees, setSelectedAttendees] = useState(0);
    const [checkFirstTimeAttendees, setCheckFirstTimeAttendees] = useState(0);
    const [selectedAttendeesData, setSelectedAttendeesData] = useState(0);
    const { eventLogId } = useParams();
    const [filteredData, setfilteredData] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [employeeCompany, setEmployeeCompany] = useState([]);
    const [updateButtonText, setUpdateButtonText] = useState(false);
    const [rsvpLoading, setRsvpLoading] = useState(false);
    const [processedItems, setProcessedItems] = useState([]);
    const [vimeoToken, setVimeoToken] = useState("");
    const [isAddCalendar, setIsAddCalendar] = useState(false);
    //const [eventCalendarData, setEventCalendarData] = useState();
    const openVideo = (mediaItem, index) => {
        setOpenModal(!openModal)
        setPreviewImage(mediaItem?.previewsrc);
        setType(mediaItem?.type);
        if (mediaItem?.type == 1) {
            const mediaData = processUrl(mediaItem?.videoSrc);

            let videoUrl = '';
            if (mediaItem.videoType === 0) {
                videoUrl=  `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
            }
            else {
                if (mediaData.type === "vimeo") {
                    videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true&loop=1`;
                }
                if (mediaData.type === "htmlVideo") {
                    videoUrl = mediaItem?.videoSrc;
                }
            }


            setVideoUrl(videoUrl);
        }
        setCurrentIndex(index);
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 300,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
   
    const nextSlide = () => {
        const eventIndex = currentIndex === event.media.length - 1 ? 0 : currentIndex + 1;
        const media = event.media[eventIndex];
        if (media) {
            setPreviewImage(media.mediaItem.previewsrc);
            setType(media.mediaItem.type);
            if (media.mediaItem.type == 1) {
                const mediaData = processUrl(media.mediaItem?.videoSrc);

                let videoUrl = '';
                if (media.mediaItem?.videoType === 0) {
                    videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
                }
                else {
                    if (mediaData.type === "vimeo") {
                        videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true&loop=1`;
                    }
                    if (mediaData.type === "htmlVideo") {
                        videoUrl = media.mediaItem?.videoSrc;
                    }
                }          
                setVideoUrl(videoUrl);
            }
        }
        setCurrentIndex(eventIndex);
    };

    const prevSlide = () => {
        const eventIndex = currentIndex === 0 ? event.media.length - 1 : currentIndex - 1;
        const media = event.media[eventIndex];
        if (media) {
            setPreviewImage(media.mediaItem.previewsrc);
            setType(media.mediaItem.type);
            if (media.mediaItem.type == 1) {
                const mediaData = processUrl(media.mediaItem?.videoSrc);
            
                let videoUrl = '';
                if (media.mediaItem?.videoType === 0) {
                    videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
                }
                else {
                    if (mediaData.type === "vimeo") {  
                        videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true&loop=1`;
                    }
                    if (mediaData.type === "htmlVideo") {
                        videoUrl = media.mediaItem?.videoSrc;
                    }
                }
      
                setVideoUrl(videoUrl);
            }
        }
        setCurrentIndex(eventIndex);
    }

    useEffect(() => {  
        dispatch(showLoading());
        dispatch(getTypeEvent());
        eventedit();
        getEmployeesCompanyList(siteUserId).then((response) => {
            let employeeCompanyResponse = response.data.data;
            setEmployeeCompany(employeeCompanyResponse);
        });
    }, []);
    useEffect(() => {
        if (event?.title) {
            document.title = event?.title + ' | Events | CWP Team Connect';
        }
    }, [event?.title]);
    useEffect(() => {
        dispatch(getTypeEvent());

        getVimeoToken().then((response) => {
            setVimeoToken(response.data);
        });

        if (baseId > 0) {           
            getEventDetailByBaseId(baseId, eventLogId).then((response) => {
                let eventResponse = response.data.data;
                if (eventResponse) {
                    //setEventCalendarData(eventResponse?.eventCalendarViewModel)
                    let filteredData = eventResponse?.eventLog.find(x => x.id == eventLogId);
                    setEvent(eventResponse);
                    console.log("filteredData:", filteredData)
                    setfilteredData(filteredData);              
                    if (filteredData === undefined) {
                        navigate('/events')
                    }
                }                
            });
        }  
    }, []);
    

    useEffect(() => {
        const processItems = async () => {
            const itemsWithVideoCheck = await Promise.all(
                event.media.filter(item => !item.mediaItem?.previewsrc?.includes("/static/media/")).map(async (item) => {
                    if (item.mediaItem?.videoType === 1) {
                        const videoExists = await isVimeoVideoExists(item.mediaItem?.videoId, vimeoToken);
                        return { ...item, videoExists };
                    }
                    return item;
                })
            );
            setProcessedItems(itemsWithVideoCheck.filter(x => (x.videoExists === true && x.mediaItem?.videoType === 1) || x.mediaItem?.videoType === 0 || x.mediaItem?.type === 0).slice(0, 5)); // Limit to 5 items
        };

        if (event && event.media && event.media.length > 0) {
            processItems();
        }        
    }, [event]);



    const eventedit = () => {
        if (baseId > 0) {   
            getEventDetailByBaseId(baseId, eventLogId).then((response) => {
                let eventResponse = response.data.data;               
                if (eventResponse) {
                    setSelectedAttendees(eventResponse.eventRSVPs ? parseInt(eventResponse?.eventRSVPs?.numPlus1s) || 0 : 0)
                    setCheckFirstTimeAttendees(eventResponse.eventRSVPs ? parseInt(eventResponse?.eventRSVPs?.numPlus1s) || 0 : 0);
                    setEvent(eventResponse);
                    dispatch(hideLoading());
                    setSelectedAttendeesData(eventResponse?.eventRSVPs?.numPlus1s);
                }                
            });
        }
    }


    const handleRSVPClick = async () => { 
        try { 
            setRsvpLoading(true);
            const rsvpData = {    
                Id: event.eventRSVPs ? parseInt(event.eventRSVPs.id) || 0 : 0,   
                NumPlus1s: (event.eventRSVPs && (event.eventRSVPs.numPlus1s === 0 || (selectedAttendees != 0 && event.eventRSVPs?.numPlus1s != selectedAttendees))) || event.eventRSVPs === undefined ? selectedAttendees : 0,
                EndTime: event.eventLog[0].endTime, 
                EventLogId: eventLogId
            };
            const response = await dispatch(getEvenRsvptUpdated(rsvpData));
            if (response.payload && response.payload.data) {
                setSelectedAttendeesData(response.payload.data.numPlus1s);
                if (checkFirstTimeAttendees > 0) {
                    setShowRsvpConfirmModal(true);
                }
                setUpdateButtonText(false);
                setRsvpLoading(false);
            }
            eventedit(); 
            console.log("RSVP Response:", response);
        } catch (error) {
            console.error("Error updating RSVP:", error);
        }
    }

    const handleRSVPYesClick = async () => {
        try {
            setRsvpLoading(true);
            var rsvpData = "";
            if (event.eventRSVPs == undefined || event.eventRSVPs == null) {
                rsvpData = {
                    Id: 0,
                    EventId: parseInt(event.baseId),
                    NumPlus1s: 1,
                    EventLogId: eventLogId
                };
            }
            else {
                rsvpData = {
                    Id: event.eventRSVPs ? parseInt(event.eventRSVPs?.id) || 0 : 0,
                    EventId: parseInt(event.baseId),
                    NumPlus1s: event.eventRSVPs.numPlus1s === 0 ? 1 : 0,
                    EventLogId: eventLogId
                };
            }
            const response = await dispatch(getEvenRsvptUpdated(rsvpData));
            if (response) {
                setRsvpLoading(false);
            }
            eventedit();
        }
        catch (error) {
            console.error("Error updating RSVP:", error);
        }
    }

    const handleSelectChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setSelectedAttendees(selectedValue);
        setUpdateButtonText(true);
    };


  
   const handleCalendarAdd = () => {     
        setIsAddCalendar(!isAddCalendar);
    };
 
    const openGoogleCalendar = (formattedStartDate, formattedEndDate) => {
        // to do if want user timezone
        //const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const createLink = "http://www.google.com/calendar/render?action=TEMPLATE&sf=true&output=xml&text=" + event.title + "&details=" + event.content + "&dates=" + formattedStartDate + "/" + formattedEndDate + "&location=" + event.eventLocation; // + "&ctz=" + timeZone;
        window.open(createLink, "_blank");
    };

   
    const callCalendarIcsAPI = async (eventDate, baseId, eventLogId) => {
        // Prepare the request payload
        const request = {
            date: eventDate,
            baseId: baseId,
            eventLogId: eventLogId
        };

        try {
            // Call the API to get the calendar file
            const res = await getCalendarIcsFile(request);

            if (res && res.data.success) {
                // Decode the Base64 IcsContent
                const blob = new Blob([Uint8Array.from(atob(res.data.data), c => c.charCodeAt(0))], { type: 'text/calendar' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;

                // Generate a filename with a timestamp
                let date = formatDate(filteredData?.eventDate, "MMM-dd");
                const fileName = createSlug(event?.title || '') + `-${date}.ics`;
                a.download = fileName;

                // Append the link to the body, click it, then remove it
                document.body.appendChild(a);
                a.click();
                a.remove();

                // Revoke the object URL after use
                window.URL.revokeObjectURL(url);
            } else {
                // Handle custom error response
                console.log('Not Found');
            }
        } catch (error) {
            console.error("Error fetching calendar file:", error);
        }
    };

    const createSlug = (text) => {
        return text
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-')          // Replace spaces with -
            .replace(/[^\w-]+/g, '')       // Remove all non-word characters (except hyphens)
            .replace(/--+/g, '-')          // Replace multiple hyphens with a single hyphen
            .replace(/^-+/, '')            // Trim hyphens from the start of the text
            .replace(/-+$/, '');           // Trim hyphens from the end of the text
    }

     
    const handleAddToCalendar = (calender, eventDate, startTime, endTime, baseId = 0, eventLogId =0) => {
         switch (calender) {
            case 'google': {
                let formattedStartDate, formattedEndDate;
                if (startTime && endTime) {
                    // Convert eventDate with startTime and endTime to Date objects
                    const startDateTime = `${eventDate} ${startTime}`;
                    const endDateTime = `${eventDate} ${endTime}`;
                    // Create Date objects
                    // Define the format of the input date string
                    const format = 'MM/DD/YYYY h:mma';
                    // Parse the date string
                    const startDate = moment(startDateTime, format).toDate();
                    const endDate = moment(endDateTime, format).toDate();
                    // Check if the dates are valid
                    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
                        console.error("Invalid date or time format.");
                        return;
                    }
                    formattedStartDate = moment(startDate, 'MM/DD/YYYY h:mma').format('YYYYMMDDTHHmmss');
                    formattedEndDate = moment(endDate, 'MM/DD/YYYY h:mma').format('YYYYMMDDTHHmmss');
                } else {
                    // Handle as an all-day event by formatting only the date
                    formattedStartDate = moment(eventDate, 'MM/DD/YYYY').format('YYYYMMDD');
                    formattedEndDate = moment(eventDate, 'MM/DD/YYYY').format('YYYYMMDD');
                }
                openGoogleCalendar(formattedStartDate, formattedEndDate);
            }
                break;
             case 'ios': 
                 eventDate = moment(eventDate).format('DD/MM/YYYY');
                callCalendarIcsAPI(eventDate, baseId, eventLogId);
                    break;    
             case 'outlook': 
                 eventDate = moment(eventDate).format('DD/MM/YYYY');
                callCalendarIcsAPI(eventDate, baseId, eventLogId);
                break;      
            default:
                console.log("Please select a valid calendar option.");
                break;
        }
    };
     
    return (
        <>
            <RsvpConfirmModal
                show={showRsvpConfirmModal}
                onClose={() => setShowRsvpConfirmModal(false)}
                confirmMessage={"The number of attendees has been updated to " + selectedAttendeesData}
            />
       
        <div className="directory-wrap event-detail-wrap">  
                <div className="arrow-header" onClick={() => navigate('/events/')}>
                    <img src={leftArrow} alt="left arrow" className="left-arrow" loading="lazy" />
                    <span>Back to Events</span>
                </div>
                <Navigation />
                <div className="events-page-wrap">
                    <div className="inner-pages-alert news-detail-alert event-alert">
                        <PortalAlert />
                    </div>
                <div className="page-inner events-inner">
                    <div className="event-detail-body">             
                        {event && (
                            <div className="event-detail-wrapper event-outer-video-wrappper">
                                    {event && event.media && event.media.length > 0 && (
                                        <Slider {...settings}>
                                            {processedItems.map((item, index) => {
                                                if (item.mediaItem?.videoType === 1) {
                                                    if (item.videoExists) {
                                                        return (
                                                            <div className="video-event-wrap" key={item.id}>
                                                                <span className="desktop-video-payer video-player" onClick={() => openVideo(item.mediaItem, index)}></span>
                                                                <img src={item.mediaItem?.previewsrc} alt="image" className="video-box" loading="lazy" />
                                                                {item.mediaItem?.type == 1 && (
                                                                    <div className="play-icon" onClick={() => openVideo(item.mediaItem, index)}>
                                                                        <img src={PlayIcon} alt="play" loading="lazy" />
                                                                    </div>
                                                                )}
                                                            </div>

                                                        );
                                                    }
                                                } else {
                                                    return (
                                                        <div className="video-event-wrap" key={item.id}>
                                                            <span className="desktop-video-payer video-player" onClick={() => openVideo(item.mediaItem, index)}></span>
                                                            <img src={item.mediaItem?.previewsrc} alt="image" className="video-box" loading="lazy" />
                                                            {item.mediaItem?.type == 1 && (
                                                                <div className="play-icon" onClick={() => openVideo(item.mediaItem, index)}>
                                                                    <img src={PlayIcon} alt="play" loading="lazy" />
                                                                </div>
                                                            )}
                                                        </div>

                                                    );
                                                }

                                                
                                            })}
                                        </Slider>
                                    )}
                                
                                    <div className={`event-detail-content-body ${event && event.media && event.media.length > 0 ? 'slider-active' : 'slider-deactive'}`}>
                                    <div className="title-header">
                                        <p className="date-box">{filteredData?.eventDate && formatDate(filteredData?.eventDate, "MMMM dd, Y")}</p>
                                        <h1 className="event-detail-title">{event?.title}</h1>
                                        <div className="event-icon-wrapper">
                                            <span className="event-icon-box">
                                                <img src={Calendar} alt="calendar" className="event-icon" loading="lazy" />
                                            </span>
                                            <p className="event-pera">
                                                    {formatLongDateWithOrdinal(filteredData?.eventDate && formatDate(filteredData?.eventDate))} {filteredData?.startTime && '@ ' + filteredData.startTime.toLowerCase().replace(" ", "")}  {filteredData?.endTime && 'to ' + filteredData?.endTime.toLowerCase().replace(" ", "")}
                                            </p>
                                        </div>
                                        <div className="event-dropdown">
                                            <div className="event-icon-wrapper" onClick={handleCalendarAdd}>
                                                        <span className="event-icon-box">
                                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.5 11C5.27738 11 5.09077 10.9247 4.94018 10.7741C4.78958 10.6235 4.71429 10.4369 4.71429 10.2143V6.28571H0.785714C0.563095 6.28571 0.376488 6.21042 0.225893 6.05982C0.0752976 5.90923 0 5.72262 0 5.5C0 5.27738 0.0752976 5.09077 0.225893 4.94018C0.376488 4.78958 0.563095 4.71429 0.785714 4.71429H4.71429V0.785714C4.71429 0.563095 4.78958 0.376488 4.94018 0.225893C5.09077 0.0752976 5.27738 0 5.5 0C5.72262 0 5.90923 0.0752976 6.05982 0.225893C6.21042 0.376488 6.28571 0.563095 6.28571 0.785714V4.71429H10.2143C10.4369 4.71429 10.6235 4.78958 10.7741 4.94018C10.9247 5.09077 11 5.27738 11 5.5C11 5.72262 10.9247 5.90923 10.7741 6.05982C10.6235 6.21042 10.4369 6.28571 10.2143 6.28571H6.28571V10.2143C6.28571 10.4369 6.21042 10.6235 6.05982 10.7741C5.90923 10.9247 5.72262 11 5.5 11Z" fill="#A4A4A4" />
                                                            </svg>
                                                        </span>
                                                        <p className="event-pera">
                                                           <a className="addcalendar" >
                                                             Add to Calendar 
                                                           </a>
                                                         </p>
                                                </div> 
                                                {isAddCalendar && (
                                            <div className="event-select-dropdown-wrap">
                                                        <div className="event-icon-wrapper" onClick={handleCalendarAdd}>
                                                        <span className="event-icon-box">
                                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.5 11C5.27738 11 5.09077 10.9247 4.94018 10.7741C4.78958 10.6235 4.71429 10.4369 4.71429 10.2143V6.28571H0.785714C0.563095 6.28571 0.376488 6.21042 0.225893 6.05982C0.0752976 5.90923 0 5.72262 0 5.5C0 5.27738 0.0752976 5.09077 0.225893 4.94018C0.376488 4.78958 0.563095 4.71429 0.785714 4.71429H4.71429V0.785714C4.71429 0.563095 4.78958 0.376488 4.94018 0.225893C5.09077 0.0752976 5.27738 0 5.5 0C5.72262 0 5.90923 0.0752976 6.05982 0.225893C6.21042 0.376488 6.28571 0.563095 6.28571 0.785714V4.71429H10.2143C10.4369 4.71429 10.6235 4.78958 10.7741 4.94018C10.9247 5.09077 11 5.27738 11 5.5C11 5.72262 10.9247 5.90923 10.7741 6.05982C10.6235 6.21042 10.4369 6.28571 10.2143 6.28571H6.28571V10.2143C6.28571 10.4369 6.21042 10.6235 6.05982 10.7741C5.90923 10.9247 5.72262 11 5.5 11Z" fill="#A4A4A4" />
                                                            </svg>
                                                        </span>
                                                        <p className="event-pera">
                                                           <a className="addcalendar" >
                                                             Add to Calendar 
                                                           </a>
                                                         </p>
                                             
                                                        </div> 
                                                    
                                                        <div className="event-select-dropdown">
                                                                <ul>
                                                                    <li onClick={() => handleAddToCalendar('ios', filteredData?.eventDate && filteredData?.eventDate, filteredData?.startTime && filteredData.startTime.toLowerCase().replace(" ", ""), filteredData?.endTime && filteredData?.endTime.toLowerCase().replace(" ", ""), baseId ? parseInt(baseId) : 0, filteredData?.id)}>Apple</li>
                                                                    <li onClick={() => handleAddToCalendar('google', filteredData?.eventDate && formatDate(filteredData?.eventDate), filteredData?.startTime && filteredData.startTime.toLowerCase().replace(" ", ""), filteredData?.endTime && filteredData?.endTime.toLowerCase().replace(" ", ""))}>Google</li>
                                                                    <li onClick={() => handleAddToCalendar('outlook', filteredData?.eventDate && filteredData?.eventDate, filteredData?.startTime && filteredData.startTime.toLowerCase().replace(" ", ""), filteredData?.endTime && filteredData?.endTime.toLowerCase().replace(" ", ""), baseId ? parseInt(baseId) : 0, filteredData?.id)}>Microsoft 365</li>
                                                                </ul>
                                                            </div>
                                                        
                                                    </div>      
                                                )}
                                        </div>
 
                                        {event.eventLocation && event.eventLocation !== '' && (
                                        <div className="event-icon-wrapper">
                                            <span className="event-icon-box">
                                                <img src={Location} alt="location" className="event-icon" loading="lazy" />
                                            </span>
                                            <p className="event-pera">{event?.eventLocation}</p>
                                            </div>
                                        )} 
                                    </div>

                                    <p className="event-pera" dangerouslySetInnerHTML={{ __html: event?.content }} />
                                </div>
                            </div>
                        )}
                    </div>
                        <div className="right-event-wrapper">
                            {event && event.eventCompanies && event.eventCompanies.length > 0 && event.eventCompanies.some(company => employeeCompany.find(empCompany => empCompany.id === company.companyId)) && (
                                (event && event.rsvpType === 'yes' || event && event.rsvpType === 'oneplus') && (
                                    <div className="event-card">
                                        {event.rsvpType === 'oneplus' && (
                                            <div className="event-select">
                                                <label className="event-label" htmlFor="slct">
                                                    # of attendees
                                                </label>
                                                <div className="filter-bars">
                                                    <select className={`menu-wrap ${selectedAttendeesData && selectedAttendeesData > 0 ? 'selected' : ''}`} name="slct" id="slct" onChange={handleSelectChange} value={selectedAttendees} >
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        {(event.rsvpType === 'oneplus') && (
                                            <button className={event && event.eventRSVPs && (event.eventRSVPs?.numPlus1s > 0 && updateButtonText === false) ? "events-rsvp-cta selected" : "event-card-button"} onClick={handleRSVPClick}>
                                                {event && event.eventRSVPs && event.eventRSVPs.numPlus1s > 0 ? (updateButtonText ? "Update RSVP" : "RSVP'd") : "RSVP"}
                                                {rsvpLoading && (
                                                    <span className="loader"></span>
                                                )}                                                
                                            </button>

                                        )}
                                        {(event.rsvpType === 'yes') && (
                                            <button className={event && event.eventRSVPs && event.eventRSVPs?.numPlus1s > 0 ? "event-card-button selected" : "event-card-button"} onClick={handleRSVPYesClick}>
                                                {event && event.eventRSVPs && event.eventRSVPs?.numPlus1s > 0 ? "RSVP'd" : "RSVP"}
                                                {rsvpLoading && event.eventRSVPs?.numPlus1s ===0 && (
                                                    <span className="loader"></span>
                                                )}
                                            </button>
                                        )}
                                    </div>
                                )
                            )}
                    </div>


                </div>
            </div>
            {openModal && (
                <div className="video-modal">
                    <div className="close-modal" onClick={openVideo}>
                        <span className="material-symbols-rounded">
                            close
                        </span>
                    </div>
                    <div className="video-event-wrap news-video">
                        {type == 0 && (<img src={previewImage} alt="video" className="video-box" loading="lazy" />)}                    
                            
                   
                            {type === 1 && (
                                <iframe
                                    width="560"
                                    height="315"
                                    src={videoUrl}
                                    title=""
                                    allow="autoplay;"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            )}


                    </div>
                    {processedItems.length > 1 && (
                        <div className="video-arrows-wrap">
                            <div className="arrow-modal prev" onClick={prevSlide}>
                                <span className="arrow-wrap">
                                    <img src={prevArrow} alt="prev item" loading="lazy" />
                                </span>
                            </div>
                            <div className="arrow-modal next" onClick={nextSlide}>
                                <span className="arrow-wrap">
                                    <img src={nextArrow} alt="next item" loading="lazy" />
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            )}
            </div>
        </>
    );
}

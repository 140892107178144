import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './detail.css';
import Sidebar from '../../components/SideBar/Sidebar.js';
import todayIcon from '../../assets/tour-sm.svg';
import { getTodayEvents } from '../../store/events/actions';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import { useParams } from 'react-router-dom';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import PropTypes from 'prop-types';
import { shopMonitorSliderTime } from '../../helpers/enums';

export default function ShopToday({ handleloadinTiming }) {
	const dispatch = useDispatch();
	const { todayEventNotifications } = useSelector(state => state.event);
	const { fetchNext } = useParams();
	const [filter] = useState({ companyId: 0, timezone: GetTimeZone() });
	const isLoading = useSelector((state) => state.loading);
	const [isDataLoaded, setIsDataLoaded] = useState(false);


	useEffect(() => {
		document.title = 'Today | CWP Team Connect';
		dispatch(showLoading());
		handleloadinTiming(shopMonitorSliderTime.LeadingTime);
		let sharpCompanyId = localStorage.getItem('monitor_mode_company');
		filter.companyId = (sharpCompanyId != null && sharpCompanyId !== undefined) ? parseInt(sharpCompanyId) : 0;
		dispatch(getTodayEvents(filter)).unwrap().then(() => {
			dispatch(hideLoading());
			handleloadinTiming(shopMonitorSliderTime.default);
			setIsDataLoaded(true);
		});
	}, []);


	useEffect(() => {
		if (isLoading && isDataLoaded) {
			dispatch(hideLoading());
			handleloadinTiming(shopMonitorSliderTime.default);
		}
	}, [isLoading, isDataLoaded]);


	return (
		<div className="monitors-inner">
			<div className="monitors-item">
				<div className="monitors-header-wrap">
					<p className="monitors-header-title">Today</p>
					<span className="monitors-header-icon"><img src={todayIcon} alt="Today Icon" /></span>
				</div>
				<div className="monitors-item-event-wrap">

					{todayEventNotifications && todayEventNotifications.length === 0 && (
						<div className="monitors-item-event-item">
							<div className="monitors-item-event-full">
								<span className="news-description">No Tours or Visitors Today</span>
							</div>
						</div>
					)}					


					{todayEventNotifications && todayEventNotifications.length > 0 && todayEventNotifications.slice(fetchNext * 5, (fetchNext + 1) * 5).map(eventLogDate => {
						// Declare the variable outside of JSX block
						let firstLogDate = null;
						if (eventLogDate.eventLog && eventLogDate.eventLog.length > 0) {
							firstLogDate = eventLogDate.eventLog[0];
						}
						return (
							firstLogDate && (
								<div className="monitors-item-event-item" key={firstLogDate.id}>
									<div className="monitors-item-event-full">
										<div className="monitors-item-event-title-wrap">
											<p className="monitors-event-title">{eventLogDate.title}</p>
										</div>
										<p className="monitors-type-desc">{eventLogDate.eventType?.name} {'@ ' + firstLogDate.startTime}</p>
									</div>
								</div>
							)
						);
					})}


				</div>
			</div>
			<Sidebar pageType={"today"} />
		</div>
	);
}

ShopToday.propTypes = {
	handleloadinTiming: PropTypes.func
};
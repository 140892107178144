import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaUploader from '../../components/euiMedia/MediaUploader.js';
import Masonry from "react-responsive-masonry";
import { useNavigate } from 'react-router-dom';

import './editprofile.css';

import { getEmployeeById, getShirtSizes, saveEmployeeInfo } from '../../store/employee/actions';
import { getInterestsList } from '../../store/interest/actions';
import { getSkillsList } from '../../store/skill/actions';
import { showLoading, hideLoading } from '../../store/loading-reducer';
import { formatPhoneNumber, formatPhoneNumberAsYouType, formatDate } from '../../helpers/formatterHelper'
import debounce from '../../helpers/debounce';

import { Scrollbar } from 'react-scrollbars-custom';


export default function EditProfile() {
    const dispatch = useDispatch();
    const { siteUserId } = useSelector(state => state.auth);
    const { employee, isEmployeeLoaded, shirtSizes } = useSelector(state => state.employee);
    const interestList = useSelector(state => state.interest.interestsList);
    const isInterestLoaded = useSelector(state => state.interest.isInterestLoaded);
    const skillList = useSelector(state => state.skill.skillsList);
    const isSkillLoaded = useSelector(state => state.skill.isSkillLoaded);
    const [employeeObject, setEmployeeObject] = useState({});
    const [showInterestOverlay, setShowInterestOverlay] = useState(false);
    const [showSkillOverlay, setShowSkillOverlay] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [popularInterests, setPopularInterests] = useState([]);
    const [alphaInterests, setAlphaInterests] = useState([]);
    const [popularSkills, setPopularSkills] = useState([]);
    const [alphaSkills, setAlphaSkills] = useState([]);
    const [viewAll, setViewAll] = useState(false);
    const [setFirstNameError] = useState('');
    const [setLastNameError] = useState('');

    const mediaEditRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showLoading());
        dispatch(getInterestsList());

        dispatch(getSkillsList({}));
        dispatch(getShirtSizes());
        dispatch(getEmployeeById({ id: siteUserId })).unwrap().then(() => {
            dispatch(hideLoading());
        });
    }, []);

    useEffect(() => {
        if (isEmployeeLoaded) {
            document.title = `Edit ${employee.fullName} - CWP Team Connect`
            setEmployeeObject(employee);
        }
    }, [isEmployeeLoaded]);

    useEffect(() => {
        if (isInterestLoaded && popularInterests.length === 0) {
            setPopularInterests(interestList.slice(0, 10).sort((a, b) => b.interestCount - a.interestCount));
            setAlphaInterests(interestList.slice(0).sort((a, b) => a.name.localeCompare(b.name)));
        }
    }, [isInterestLoaded]);

    useEffect(() => {
        if (isSkillLoaded && popularSkills.length === 0) {
            setPopularSkills(skillList.slice(0, 10).sort((a, b) => b.skillCount - a.skillCount));
            setAlphaSkills(skillList.slice(0).sort((a, b) => a.name.localeCompare(b.name)));
        }
    }, [isSkillLoaded]);

    const matchedText = (text) => {
        if (text) {
            let startIndex = text.toLowerCase().indexOf(searchText.toLowerCase());
            if (startIndex === -1) {
                return (
                    <>{text}</>
                );
            } else {
                let startText = text.substring(0, startIndex);
                let matchedText = text.substring(startIndex, startIndex + searchText.length);
                let endText = text.substring(startIndex + searchText.length, text.length);
                return (
                    <>{startText}<span className="match">{matchedText}</span>{endText}</>
                );
            }
        } else {
            return (
                <></>
            );
        }
    };

    const updatePhotoEvent = () => {
        let editElement = mediaEditRef.current.querySelector('[data-action="media-edit-click"]');
        if (editElement != undefined && editElement != null) { editElement.click(); }
        else {
            let addElement = mediaEditRef.current.querySelector('[data-action="media-add-click"]');
            addElement.click();
        }
    } 
    
    const popularSection = () => {
        return (
            <>
                <div className="edit-profile-add-list-header">
                    <p className="edit-profile-add-list-title">{!viewAll ? 'Popular' : 'All'} {showInterestOverlay ? "Interests" : "Positions & Qualifications"}</p>                
                </div>
                {showInterestOverlay && (
                    <div>
                        <div className="edit-profile-add-list-flex">
                            {!viewAll && popularInterests && popularInterests.map((interest) => {
                                return (
                                    <button className="edit-profile-add-list-interest" key={interest.id} onClick={(e) => { e.preventDefault(); handleInterestClick(interest); }}>
                                        <span className="edit-profile-add-list-interest-text">{interest.name}</span>
                                        <span className="edit-profile-add-list-interest-add">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.44112 7.55881L1.0603 7.5588C0.913446 7.55881 0.783586 7.50408 0.670723 7.39461C0.557859 7.28516 0.501433 7.1536 0.501441 6.99994C0.501432 6.84628 0.556158 6.71472 0.665619 6.60526C0.775078 6.4958 0.90664 6.44107 1.0603 6.44108L6.44112 6.44106L6.44114 1.06024C6.44113 0.913386 6.49586 0.783526 6.60533 0.670663C6.71478 0.5578 6.84634 0.501373 7 0.501382C7.15366 0.501373 7.28522 0.556099 7.39468 0.665559C7.50414 0.775019 7.55887 0.90658 7.55886 1.06024L7.55887 6.44106L12.9397 6.44108C13.0866 6.44107 13.2164 6.4958 13.3293 6.60527C13.4421 6.71472 13.4986 6.84628 13.4986 6.99994C13.4986 7.1536 13.4438 7.28516 13.3344 7.39462C13.2249 7.50408 13.0934 7.55881 12.9397 7.5588L7.55887 7.55881L7.55886 12.9396C7.55887 13.0865 7.50414 13.2164 7.39467 13.3292C7.28522 13.4421 7.15366 13.4985 7 13.4985C6.84634 13.4985 6.71477 13.4438 6.60531 13.3343C6.49585 13.2249 6.44113 13.0933 6.44114 12.9396L6.44112 7.55881Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                    </button>
                                )
                            })}
                            {viewAll && alphaInterests && alphaInterests.map((interest) => {
                                return (
                                    <button className="edit-profile-add-list-interest" key={interest.id} onClick={(e) => { e.preventDefault(); handleInterestClick(interest); }}>
                                        <span className="edit-profile-add-list-interest-text">{interest.name}</span>
                                        <span className="edit-profile-add-list-interest-add">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.44112 7.55881L1.0603 7.5588C0.913446 7.55881 0.783586 7.50408 0.670723 7.39461C0.557859 7.28516 0.501433 7.1536 0.501441 6.99994C0.501432 6.84628 0.556158 6.71472 0.665619 6.60526C0.775078 6.4958 0.90664 6.44107 1.0603 6.44108L6.44112 6.44106L6.44114 1.06024C6.44113 0.913386 6.49586 0.783526 6.60533 0.670663C6.71478 0.5578 6.84634 0.501373 7 0.501382C7.15366 0.501373 7.28522 0.556099 7.39468 0.665559C7.50414 0.775019 7.55887 0.90658 7.55886 1.06024L7.55887 6.44106L12.9397 6.44108C13.0866 6.44107 13.2164 6.4958 13.3293 6.60527C13.4421 6.71472 13.4986 6.84628 13.4986 6.99994C13.4986 7.1536 13.4438 7.28516 13.3344 7.39462C13.2249 7.50408 13.0934 7.55881 12.9397 7.5588L7.55887 7.55881L7.55886 12.9396C7.55887 13.0865 7.50414 13.2164 7.39467 13.3292C7.28522 13.4421 7.15366 13.4985 7 13.4985C6.84634 13.4985 6.71477 13.4438 6.60531 13.3343C6.49585 13.2249 6.44113 13.0933 6.44114 12.9396L6.44112 7.55881Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                    </button>
                                )
                            })}
                        </div>
                        <div className="edit-profile-add-list-header">
                            {!viewAll &&
                                <button className="edit-profile-add-list-all" onClick={(e) => { e.preventDefault(); setViewAll(true); }}>View All</button>
                            }
                        </div>
                    </div>
                )}

                {showSkillOverlay && (
                    <div>
                        <div className="edit-profile-add-list-flex skill">
                            <Masonry columnsCount={2}>
                                {!viewAll && popularSkills && popularSkills
                                    .filter(item =>
                                        item.id !== employee.primaryPositionId &&
                                        item.skillCompanies.some(company =>
                                            company?.companyId === employee.primaryCompanyId ||
                                            employee.userCompanies.some(secondary => secondary.companyId === company?.companyId)
                                        ) &&
                                        !employee.userSkills.some(userSkill => userSkill.skillId === item.id)
                                    )
                                    .map((skill) => {
                                    return (
                                        <button className="edit-profile-add-list-skill" key={skill.id} onClick={(e) => { e.preventDefault(); handleSkillClick(skill); }}>
                                            <span className="edit-profile-add-list-skill-text">{skill.name}</span>
                                            <span className="edit-profile-add-list-skill-add">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.44112 7.55881L1.0603 7.5588C0.913446 7.55881 0.783586 7.50408 0.670723 7.39461C0.557859 7.28516 0.501433 7.1536 0.501441 6.99994C0.501432 6.84628 0.556158 6.71472 0.665619 6.60526C0.775078 6.4958 0.90664 6.44107 1.0603 6.44108L6.44112 6.44106L6.44114 1.06024C6.44113 0.913386 6.49586 0.783526 6.60533 0.670663C6.71478 0.5578 6.84634 0.501373 7 0.501382C7.15366 0.501373 7.28522 0.556099 7.39468 0.665559C7.50414 0.775019 7.55887 0.90658 7.55886 1.06024L7.55887 6.44106L12.9397 6.44108C13.0866 6.44107 13.2164 6.4958 13.3293 6.60527C13.4421 6.71472 13.4986 6.84628 13.4986 6.99994C13.4986 7.1536 13.4438 7.28516 13.3344 7.39462C13.2249 7.50408 13.0934 7.55881 12.9397 7.5588L7.55887 7.55881L7.55886 12.9396C7.55887 13.0865 7.50414 13.2164 7.39467 13.3292C7.28522 13.4421 7.15366 13.4985 7 13.4985C6.84634 13.4985 6.71477 13.4438 6.60531 13.3343C6.49585 13.2249 6.44113 13.0933 6.44114 12.9396L6.44112 7.55881Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </button>
                                    )
                                })}
                                {viewAll && alphaSkills && alphaSkills
                                    .filter(item =>
                                        item.id !== employee.primaryPositionId &&
                                        item.skillCompanies.some(company =>
                                            company?.companyId === employee.primaryCompanyId ||
                                            employee.userCompanies.some(secondary => secondary.companyId === company?.companyId)
                                        ) &&
                                        !employee.userSkills.some(userSkill => userSkill.skillId === item.id)
                                    )
                                    .map((skill) => {
                                    return (
                                        <button className="edit-profile-add-list-skill" key={skill.id} onClick={(e) => { e.preventDefault(); handleSkillClick(skill); }}>
                                            <span className="edit-profile-add-list-skill-text">{skill.name}</span>
                                            <span className="edit-profile-add-list-skill-add">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.44112 7.55881L1.0603 7.5588C0.913446 7.55881 0.783586 7.50408 0.670723 7.39461C0.557859 7.28516 0.501433 7.1536 0.501441 6.99994C0.501432 6.84628 0.556158 6.71472 0.665619 6.60526C0.775078 6.4958 0.90664 6.44107 1.0603 6.44108L6.44112 6.44106L6.44114 1.06024C6.44113 0.913386 6.49586 0.783526 6.60533 0.670663C6.71478 0.5578 6.84634 0.501373 7 0.501382C7.15366 0.501373 7.28522 0.556099 7.39468 0.665559C7.50414 0.775019 7.55887 0.90658 7.55886 1.06024L7.55887 6.44106L12.9397 6.44108C13.0866 6.44107 13.2164 6.4958 13.3293 6.60527C13.4421 6.71472 13.4986 6.84628 13.4986 6.99994C13.4986 7.1536 13.4438 7.28516 13.3344 7.39462C13.2249 7.50408 13.0934 7.55881 12.9397 7.5588L7.55887 7.55881L7.55886 12.9396C7.55887 13.0865 7.50414 13.2164 7.39467 13.3292C7.28522 13.4421 7.15366 13.4985 7 13.4985C6.84634 13.4985 6.71477 13.4438 6.60531 13.3343C6.49585 13.2249 6.44113 13.0933 6.44114 12.9396L6.44112 7.55881Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </button>
                                    )
                                })}
                            </Masonry>
                        </div>
                        <div className="edit-profile-add-list-header">
                            {!viewAll &&
                                <button className="edit-profile-add-list-all" onClick={(e) => { e.preventDefault(); setViewAll(true); }}>View All</button>
                            }
                        </div>
                    </div>
                )}
            </>
        );
    };

    const searchSection = () => {
        return (
            <>
                {showInterestOverlay && (
                    <div className="edit-profile-add-list-flex">
                        {interestList && interestList.map((interest) => {
                            return (
                                <button className="edit-profile-add-list-interest-match" key={interest.id} onClick={(e) => { e.preventDefault(); handleInterestClick(interest); }}>
                                    <span className="edit-profile-add-list-interest-match-text">
                                        {matchedText(interest.name)}
                                    </span>
                                    <span className="edit-profile-add-list-interest-add">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.44112 7.55881L1.0603 7.5588C0.913446 7.55881 0.783586 7.50408 0.670723 7.39461C0.557859 7.28516 0.501433 7.1536 0.501441 6.99994C0.501432 6.84628 0.556158 6.71472 0.665619 6.60526C0.775078 6.4958 0.90664 6.44107 1.0603 6.44108L6.44112 6.44106L6.44114 1.06024C6.44113 0.913386 6.49586 0.783526 6.60533 0.670663C6.71478 0.5578 6.84634 0.501373 7 0.501382C7.15366 0.501373 7.28522 0.556099 7.39468 0.665559C7.50414 0.775019 7.55887 0.90658 7.55886 1.06024L7.55887 6.44106L12.9397 6.44108C13.0866 6.44107 13.2164 6.4958 13.3293 6.60527C13.4421 6.71472 13.4986 6.84628 13.4986 6.99994C13.4986 7.1536 13.4438 7.28516 13.3344 7.39462C13.2249 7.50408 13.0934 7.55881 12.9397 7.5588L7.55887 7.55881L7.55886 12.9396C7.55887 13.0865 7.50414 13.2164 7.39467 13.3292C7.28522 13.4421 7.15366 13.4985 7 13.4985C6.84634 13.4985 6.71477 13.4438 6.60531 13.3343C6.49585 13.2249 6.44113 13.0933 6.44114 12.9396L6.44112 7.55881Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                </button>
                            )
                        })}
                        <button className="edit-profile-add-list-interest-new" onClick={(e) => { e.preventDefault(); handleAddInterestClick(searchText); }}>
                            <span className="edit-profile-add-list-interest-new-text">{searchText}</span>
                            <span className="edit-profile-add-list-interest-new-cta">Create</span>
                        </button>
                    </div>
                )}

                {showSkillOverlay && (
                    <div className="edit-profile-add-list-flex">
                        {skillList && skillList
                            .filter(item => 
                                item.id !== employee.primaryPositionId &&
                                item.skillCompanies.some(company =>
                                    company?.companyId === employee.primaryCompanyId ||
                                    employee.userCompanies.some(secondary => secondary.companyId === company?.companyId)
                                ) && 
                                !employee.userSkills.some(userSkill => userSkill.skillId === item.id)
                            )
                            .map((skill) => {
                            return (
                                <button className="edit-profile-add-list-skill-match" key={skill.id} onClick={(e) => { e.preventDefault(); handleSkillClick(skill); }}>
                                    <span className="edit-profile-add-list-skill-match-text">
                                        {matchedText(skill.name)}
                                    </span>
                                    <span className="edit-profile-add-list-skill-add">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.44112 7.55881L1.0603 7.5588C0.913446 7.55881 0.783586 7.50408 0.670723 7.39461C0.557859 7.28516 0.501433 7.1536 0.501441 6.99994C0.501432 6.84628 0.556158 6.71472 0.665619 6.60526C0.775078 6.4958 0.90664 6.44107 1.0603 6.44108L6.44112 6.44106L6.44114 1.06024C6.44113 0.913386 6.49586 0.783526 6.60533 0.670663C6.71478 0.5578 6.84634 0.501373 7 0.501382C7.15366 0.501373 7.28522 0.556099 7.39468 0.665559C7.50414 0.775019 7.55887 0.90658 7.55886 1.06024L7.55887 6.44106L12.9397 6.44108C13.0866 6.44107 13.2164 6.4958 13.3293 6.60527C13.4421 6.71472 13.4986 6.84628 13.4986 6.99994C13.4986 7.1536 13.4438 7.28516 13.3344 7.39462C13.2249 7.50408 13.0934 7.55881 12.9397 7.5588L7.55887 7.55881L7.55886 12.9396C7.55887 13.0865 7.50414 13.2164 7.39467 13.3292C7.28522 13.4421 7.15366 13.4985 7 13.4985C6.84634 13.4985 6.71477 13.4438 6.60531 13.3343C6.49585 13.2249 6.44113 13.0933 6.44114 12.9396L6.44112 7.55881Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                </button>
                            )
                        })}            
                    </div>
                )}
            </>
        );
    };

    const handleInterestClick = (interest) => {
        if (employeeObject.userInterests.findIndex(u => u.interestId === interest.id) === -1) {
            setEmployeeObject(object => ({
                ...object,
                userInterests: [
                    ...object.userInterests,
                    {
                        interestId: interest.id,
                        interest: interest
                    }
                ]
            }));
        }

        closeOverlay();
    };

    const handleAddInterestClick = (name) => {
        if (interestList.findIndex(i => i.name === name) === -1 && employeeObject.userInterests.findIndex(ui => ui.interest.name === name) === -1) {
            setEmployeeObject(object => ({
                ...object,
                userInterests: [
                    ...object.userInterests,
                    {
                        interestId: 0,
                        interest: {
                            name: name
                        }
                    }
                ]
            }));
        }
        closeOverlay();
    };

    const handleSkillClick = (skill) => {
        if (employeeObject.userSkills.findIndex(u => u.skillId === skill.id) === -1) {
            setEmployeeObject(object => ({
                ...object,
                userSkills: [
                    ...object.userSkills,
                    {
                        skillId: skill.id,
                        skill: skill
                    }
                ]
            }));
        }

        closeOverlay();
    };


    const handleDeleteInterestClick = (userInterest) => {
        const index = employeeObject.userInterests.findIndex(ui => ui.interestId === userInterest.interestId);
        if (index > -1) {
            const updatedItems = [...employeeObject.userInterests];
            updatedItems.splice(index, 1);

            setEmployeeObject(object => ({
                ...object,
                userInterests: updatedItems
            }));
        }
    };

    const handleDeleteSkillClick = (userSkill) => {
        const index = employeeObject.userSkills.findIndex(ui => ui.skillId === userSkill.skillId);
        if (index > -1) {
            const updatedItems = [...employeeObject.userSkills];
            updatedItems.splice(index, 1);

            setEmployeeObject(object => ({
                ...object,
                userSkills: updatedItems
            }));
        }
    };

    const saveProfile = async () => {
        dispatch(showLoading());
        if (!employeeObject.firstName || employeeObject.firstName.trim() == '' ||
            !employeeObject.lastName || employeeObject.lastName.trim() == '') {
            dispatch(hideLoading());
            if (!employeeObject.firstName || employeeObject.firstName.trim() == '') {
                setFirstNameError('First Name is required');
            } else {
                setFirstNameError('');
            }

            if (!employeeObject.lastName || employeeObject.lastName.trim() == '') {
                setLastNameError('Last Name is required');
            } else {
                setLastNameError('');
            }
            return;
        }

        await dispatch(saveEmployeeInfo(employeeObject));
        dispatch(getEmployeeById({ id: siteUserId })).unwrap().then((res) => {
            if (res.success) {
                navigate(-1);
            } else {
                console.log("res", res?.message);
            }
        });    
    };

    const closeOverlay = () => {
        setSearchText('');
        setViewAll(false);

        setShowInterestOverlay(false);
        setShowSkillOverlay(false);
    }

    const handleOverlayClick = (e) => {       
        if (e.target.className === 'edit-page-content') {
            history.back();
        }
    };

    const handleSkilOverlayClick = (e) => {
        if (e.target.className === 'edit-profile-add-overlay-wrap') {
            closeOverlay();
        }
    };    

    return (
        <div className="edit-page-content" onClick={handleOverlayClick}>
            <div className="edit-profile-wrap">
                <Scrollbar
                    wrapperProps={{ className: 'custom-scrollbar-wrapper' }}
                    thumbMinSize={30}
                    trackYProps={{ className: 'custom-scrollbar-track-y' }}
                    thumbYProps={{ className: 'custom-scrollbar-thumb-y' }}
                >
                <div className="edit-profile-close-wrap">
                <a className="edit-profile-close" onClick={() => history.back()} title="Close">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.00036 5.79037L1.19553 9.59518C1.09169 9.69903 0.961168 9.75215 0.803956 9.75455C0.646756 9.75697 0.513831 9.70384 0.405181 9.59518C0.296519 9.48653 0.242188 9.3548 0.242188 9.2C0.242188 9.04521 0.296519 8.91348 0.405181 8.80483L4.20999 5L0.405181 1.19518C0.301331 1.09134 0.248206 0.960818 0.245806 0.803605C0.243394 0.646405 0.296519 0.51348 0.405181 0.40483C0.513831 0.296168 0.645556 0.241837 0.800356 0.241837C0.955156 0.241837 1.08688 0.296168 1.19553 0.40483L5.00036 4.20964L8.80518 0.40483C8.90902 0.30098 9.03954 0.247855 9.19675 0.245455C9.35395 0.243043 9.48688 0.296168 9.59553 0.40483C9.70419 0.51348 9.75852 0.645205 9.75852 0.800005C9.75852 0.954805 9.70419 1.08653 9.59553 1.19518L5.79072 5L9.59553 8.80483C9.69938 8.90867 9.7525 9.03919 9.7549 9.19641C9.75732 9.35361 9.70419 9.48653 9.59553 9.59518C9.48688 9.70384 9.35515 9.75817 9.20035 9.75817C9.04555 9.75817 8.91383 9.70384 8.80518 9.59518L5.00036 5.79037Z" fill="currentColor" />
                    </svg>
                    </a>
                </div>
                
                <div className="edit-profile-top-section">
                <h1 className="edit-profile-heading">Edit <br />Profile</h1>
               
                <a className="edit-profile-image-wrap">
                    <span className="edit-profile-image" ref={mediaEditRef}>
                        <MediaUploader
                            imageOnly={true}
                            allowGallery={false}
                            forceCrop={true}
                            isPlaceHolder={true}
                            requireAltText={false}
                            mediaItems={(employeeObject?.profileImage != undefined && employeeObject?.profileImage !== null ? [employeeObject.profileImage] : [])}
                            saveMediaItems={(mediaItems) => {
                                setEmployeeObject({
                                    ...employeeObject,
                                    profileImage: (mediaItems[0] != undefined && mediaItems[0] !== null) ? mediaItems[0] : null,
                                    profileImageId: (mediaItems[0] != undefined && mediaItems[0] !== null) ? mediaItems[0].id : null
                                })
                            }}
                        />
                    </span>
                    <span className="edit-profile-image-text upload-photo" onClick={updatePhotoEvent} >Update Photo</span>
                </a>
            </div>
            <div className="edit-profile-light-section">
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="primary">First Name (Contact HR to update)</label>
                        <span className="edit-profile-uneditable">{employeeObject.firstName}</span>
                    </div>
                </div>
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="primary">Last Name (Contact HR to update)</label>
                        <span className="edit-profile-uneditable">{employeeObject.lastName}</span>
                    </div>
                </div>
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="nickname">Nickname</label>
                        <input className="edit-profile-input" id="nickname" type="text" placeholder="Optional" value={employeeObject.nickname} onChange={(e) => { setEmployeeObject({ ...employeeObject, nickname: e.target.value }); }} />
                    </div>
                </div>
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="work">Work Phone Number</label>
                        <input className="edit-profile-input" id="work" type="tel" value={employeeObject.workPhone} onChange={(e) => { const formatted = formatPhoneNumberAsYouType(e.target.value); setEmployeeObject({ ...employeeObject, workPhone: formatted }); }} />
                    </div>
                    <div className="edit-profile-input-wrap width32">
                        <label className="edit-profile-label" htmlFor="ext">Ext.</label>
                        <input className="edit-profile-input" id="ext" type="number" defaultValue={employeeObject.workPhoneExt} onChange={(e) => { setEmployeeObject({ ...employeeObject, workPhoneExt: e.target.value }); }} />
                    </div>
                </div>
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="bio">Bio</label>
                        <textarea className="edit-profile-textarea" rows="3" defaultValue={employeeObject.biography} onChange={(e) => { setEmployeeObject({ ...employeeObject, biography: e.target.value }); }}></textarea>
                    </div>
                </div>

                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                            <label className="edit-profile-label" htmlFor="shirtsize">Shirt Size</label>
                            <select className="edit-profile-input edit-profile-select" value={employeeObject.shirtSizeId} onChange={(e) => { setEmployeeObject({ ...employeeObject, shirtSizeId: e.target.value }); }}>
                                <option value="0">Select Shirt Size</option>
                                {shirtSizes && shirtSizes.length > 0 && shirtSizes.map(size => (
                                    <option value={size.id} key={size.id}>{size.name}</option>
                                ))}
                            </select>     
                    </div>
                        </div>
                        {employeeObject.primarySkill?.name &&
                            <div className="edit-profile-mod">
                                <div className="edit-profile-input-wrap">
                                    <label className="edit-profile-label" htmlFor="primary">Primary Position (Contact HR to update)</label>
                                    <span className="edit-profile-uneditable">{employeeObject.primarySkill?.name}</span>
                                </div>
                            </div>
                        }

                        {employeeObject.userSkills && employeeObject.userSkills.length > 0 &&
                            <div className="edit-profile-mod">
                                <div className="edit-profile-input-wrap">
                                    <label className="edit-profile-label" htmlFor="skills">Positions & Qualifications (Contact HR to update)</label>
                                    <div className="edit-profile-interest-wrap edit-profile-interest-name"> {/* Add the class here */}
                                        {employeeObject.userSkills && employeeObject.userSkills.map((userSkill) => {
                                            return (
                                                <span className="edit-profile-interest profile-page-skill" key={userSkill.skill.id}>
                                                    <span className="edit-profile-interest-name">{userSkill.skill.name}</span>
                                                    <button className="edit-profile-interest-delete" onClick={(e) => { e.preventDefault(); handleDeleteSkillClick(userSkill); }}>
                                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.00036 5.79036L1.19553 9.59517C1.09169 9.69901 0.961168 9.75214 0.803956 9.75454C0.646756 9.75695 0.513831 9.70383 0.405181 9.59517C0.296519 9.48652 0.242188 9.35479 0.242188 9.19999C0.242188 9.04519 0.296519 8.91347 0.405181 8.80482L4.20999 4.99999L0.405181 1.19516C0.301331 1.09133 0.248206 0.960803 0.245806 0.80359C0.243394 0.64639 0.296519 0.513465 0.405181 0.404815C0.513831 0.296152 0.645556 0.241821 0.800356 0.241821C0.955156 0.241821 1.08688 0.296152 1.19553 0.404815L5.00036 4.20962L8.80518 0.404815C8.90902 0.300965 9.03954 0.24784 9.19675 0.24544C9.35395 0.243027 9.48688 0.296152 9.59553 0.404815C9.70419 0.513465 9.75852 0.64519 9.75852 0.79999C9.75852 0.95479 9.70419 1.08651 9.59553 1.19516L5.79072 4.99999L9.59553 8.80482C9.69938 8.90865 9.7525 9.03918 9.7549 9.19639C9.75732 9.35359 9.70419 9.48652 9.59553 9.59517C9.48688 9.70383 9.35515 9.75816 9.20035 9.75816C9.04555 9.75816 8.91383 9.70383 8.80518 9.59517L5.00036 5.79036Z" fill="currentColor" />
                                                        </svg>
                                                    </button>
                                                </span>
                                            )
                                        })}

                                        {/*//to do if you want add on eui*/}
                                        {/*<button className="profile-page-skill edit-profile-interest-add" onClick={(e) => { e.preventDefault(); setShowSkillOverlay(true); }}>*/}
                                        {/*        <span className="edit-profile-interest-add-text">Add Positions & Qualifications</span>*/}
                                        {/*<span className="edit-profile-interest-add-icon">*/}
                                        {/*    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*        <path d="M6.44112 7.55881L1.0603 7.5588C0.913446 7.55881 0.783586 7.50408 0.670723 7.39461C0.557859 7.28516 0.501433 7.1536 0.501441 6.99994C0.501432 6.84628 0.556158 6.71472 0.665619 6.60526C0.775078 6.4958 0.90664 6.44107 1.0603 6.44108L6.44112 6.44106L6.44114 1.06024C6.44113 0.913386 6.49586 0.783526 6.60533 0.670663C6.71478 0.5578 6.84634 0.501373 7 0.501382C7.15366 0.501373 7.28522 0.556099 7.39468 0.665559C7.50414 0.775019 7.55887 0.90658 7.55886 1.06024L7.55887 6.44106L12.9397 6.44108C13.0866 6.44107 13.2164 6.4958 13.3293 6.60527C13.4421 6.71472 13.4986 6.84628 13.4986 6.99994C13.4986 7.1536 13.4438 7.28516 13.3344 7.39462C13.2249 7.50408 13.0934 7.55881 12.9397 7.5588L7.55887 7.55881L7.55886 12.9396C7.55887 13.0865 7.50414 13.2164 7.39467 13.3292C7.28522 13.4421 7.15366 13.4985 7 13.4985C6.84634 13.4985 6.71477 13.4438 6.60531 13.3343C6.49585 13.2249 6.44113 13.0933 6.44114 12.9396L6.44112 7.55881Z" fill="currentColor" />*/}
                                        {/*    </svg>*/}
                                        {/*</span>*/}
                                        {/*</button> */}
                                    </div>
                                </div>
                            </div>
                        }
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="interests">Interests</label>
                        <div className="edit-profile-interest-wrap">
                            {employeeObject.userInterests && employeeObject.userInterests.map((userInterest) => {
                                return (
                                    <span className="edit-profile-interest" key={userInterest.interest.id}>
                                        <span className="edit-profile-interest-name">{userInterest.interest.name}</span>
                                        <button className="edit-profile-interest-delete" onClick={(e) => { e.preventDefault(); handleDeleteInterestClick(userInterest); }}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.00036 5.79036L1.19553 9.59517C1.09169 9.69901 0.961168 9.75214 0.803956 9.75454C0.646756 9.75695 0.513831 9.70383 0.405181 9.59517C0.296519 9.48652 0.242188 9.35479 0.242188 9.19999C0.242188 9.04519 0.296519 8.91347 0.405181 8.80482L4.20999 4.99999L0.405181 1.19516C0.301331 1.09133 0.248206 0.960803 0.245806 0.80359C0.243394 0.64639 0.296519 0.513465 0.405181 0.404815C0.513831 0.296152 0.645556 0.241821 0.800356 0.241821C0.955156 0.241821 1.08688 0.296152 1.19553 0.404815L5.00036 4.20962L8.80518 0.404815C8.90902 0.300965 9.03954 0.24784 9.19675 0.24544C9.35395 0.243027 9.48688 0.296152 9.59553 0.404815C9.70419 0.513465 9.75852 0.64519 9.75852 0.79999C9.75852 0.95479 9.70419 1.08651 9.59553 1.19516L5.79072 4.99999L9.59553 8.80482C9.69938 8.90865 9.7525 9.03918 9.7549 9.19639C9.75732 9.35359 9.70419 9.48652 9.59553 9.59517C9.48688 9.70383 9.35515 9.75816 9.20035 9.75816C9.04555 9.75816 8.91383 9.70383 8.80518 9.59517L5.00036 5.79036Z" fill="currentColor" />
                                            </svg>
                                        </button>
                                    </span>
                                )
                            })}
                            <button className="edit-profile-interest-add" onClick={(e) => { e.preventDefault(); setShowInterestOverlay(true); }}>
                                <span className="edit-profile-interest-add-text">Add Interest</span>
                                <span className="edit-profile-interest-add-icon">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.44112 7.55881L1.0603 7.5588C0.913446 7.55881 0.783586 7.50408 0.670723 7.39461C0.557859 7.28516 0.501433 7.1536 0.501441 6.99994C0.501432 6.84628 0.556158 6.71472 0.665619 6.60526C0.775078 6.4958 0.90664 6.44107 1.0603 6.44108L6.44112 6.44106L6.44114 1.06024C6.44113 0.913386 6.49586 0.783526 6.60533 0.670663C6.71478 0.5578 6.84634 0.501373 7 0.501382C7.15366 0.501373 7.28522 0.556099 7.39468 0.665559C7.50414 0.775019 7.55887 0.90658 7.55886 1.06024L7.55887 6.44106L12.9397 6.44108C13.0866 6.44107 13.2164 6.4958 13.3293 6.60527C13.4421 6.71472 13.4986 6.84628 13.4986 6.99994C13.4986 7.1536 13.4438 7.28516 13.3344 7.39462C13.2249 7.50408 13.0934 7.55881 12.9397 7.5588L7.55887 7.55881L7.55886 12.9396C7.55887 13.0865 7.50414 13.2164 7.39467 13.3292C7.28522 13.4421 7.15366 13.4985 7 13.4985C6.84634 13.4985 6.71477 13.4438 6.60531 13.3343C6.49585 13.2249 6.44113 13.0933 6.44114 12.9396L6.44112 7.55881Z" fill="currentColor" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="altphone">Alternate Cell Phone to Share Your Account (optional)</label>
                        <input className="edit-profile-input"
                            id="altphone"
                            onChange={(e) => { const formatted = formatPhoneNumberAsYouType(e.target.value); setEmployeeObject({ ...employeeObject, alternatePhone: formatted }); }}
                            placeholder="example: spouse's cell phone number"
                            value={formatPhoneNumberAsYouType(employeeObject.alternatePhone)}
                            type="tel"
                        />
                    </div>
                </div>
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="primary">Primary Company (Contact HR to update)</label>
                            <span className="edit-profile-uneditable">{employeeObject.primaryCompanyName}</span>
                    </div>
                </div>
                {employeeObject.userDepartments && employeeObject.userDepartments.length > 0 &&
                    <div className="edit-profile-mod">
                        <div className="edit-profile-input-wrap">
                            <label className="edit-profile-label" htmlFor="primary">Primary Department (Contact HR to update)</label>
                            {employeeObject.userDepartments && employeeObject.userDepartments.length > 0 && employeeObject.userDepartments.filter(item => item.isSecondaryDepartment == false).map((userDepartment) => {
                                return <span className="edit-profile-uneditable" key={userDepartment.department.id}>{userDepartment.department.name}</span>
                            })}
                        </div>
                    </div>
                        }
						{employeeObject.userCompanies && employeeObject.userCompanies.length > 0 && employeeObject.userDepartments && employeeObject.userDepartments.filter(item => item.isSecondaryDepartment === true).length > 0 &&
							<div className="edit-profile-mod">
								<div className="edit-profile-input-wrap">
									<label className="edit-profile-label" htmlFor="secondary">Secondary Departments (Contact HR to update)</label>
									{employeeObject.userDepartments && employeeObject.userDepartments.length > 0 && employeeObject.userDepartments.filter(item => item.isSecondaryDepartment === true).map((userDepartment) => {
										return <span className="edit-profile-uneditable" key={userDepartment.department.id}>{userDepartment.department.name}</span>
									})}
								</div>
							</div>
						}

                {employeeObject.userCompanies && employeeObject.userCompanies.length > 0 &&
                    <div className="edit-profile-mod">
                        <div className="edit-profile-input-wrap">
                            <label className="edit-profile-label" htmlFor="secondary">Secondary Companies (Contact HR to update)</label>
                            {employeeObject.userCompanies && employeeObject.userCompanies.length > 0 && employeeObject.userCompanies.map((userCompany) => {
                                return <span className="edit-profile-uneditable" key={userCompany.companies.id}>{userCompany.companies.abbreviatedName}</span>
                            })}
                        </div>
                    </div>
                }

                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="start">Start Date (Contact HR to update)</label>
                        <span className="edit-profile-uneditable" id="start" type="date"> {formatDate(employeeObject.startDate, "MMM dd, yyyy")} </span>
                    </div>
                </div>
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="birthday">Birthday (Contact HR to update)</label>
                        <label className="edit-profile-input-checkbox-label" htmlFor="hidebday">
                            <input className="edit-profile-input-checkbox" type="checkbox" id="hidebday" checked={employeeObject.hideBirthDate ? true : false} onChange={(e) => { setEmployeeObject({ ...employeeObject, hideBirthDate: e.target.checked }); }} />
                            <span className="edit-profile-input-checkbox-label-text">Hide Birthday</span>
                        </label>
                        <span className="edit-profile-uneditable" id="birthday" type="date"> {formatDate(employeeObject.birthDate, "MMM dd, yyyy")} </span>
                    </div>
                </div>
                <div className="edit-profile-mod">
                    <div className="edit-profile-input-wrap">
                        <label className="edit-profile-label" htmlFor="mobile">Mobile Number (Contact HR to update)</label>
                        <div className="edit-profile-flex">
                            <span className="edit-profile-uneditable">{formatPhoneNumber(employeeObject.mobile)}</span>
                            <label className="edit-profile-uneditable-label">
                                <input className="edit-profile-uneditable-checkbox" type="checkbox" checked={employeeObject.hideMobile ? true : false} onChange={(e) => { setEmployeeObject({ ...employeeObject, hideMobile: e.target.checked }); }} />
                                <span className="edit-profile-uneditable-text">Hide Mobile Number</span>
                            </label>
                        </div>
                    </div>
                    </div>
                </div>
                    
            <div className="edit-profile-sticky-footer-bar">
                <button className="edit-profile-sticky-save" onClick={(e) => { e.preventDefault(); saveProfile(); }}>Save</button>
            </div>
            {(showInterestOverlay || showSkillOverlay) && (
                <div className="edit-profile-add-overlay-wrap" onClick={handleSkilOverlayClick}>
                    
                    <div className="edit-profile-add-overlay">
                        <div className="edit-profile-add-top">
                                    <p className="edit-profile-add-headline">Add {showInterestOverlay ? "Interest" : "Positions & Qualifications"}</p>
                            <button className="edit-profile-add-close" onClick={(e) => { e.preventDefault(); closeOverlay(); }}>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.00036 5.79037L1.19553 9.59518C1.09169 9.69903 0.961168 9.75215 0.803956 9.75455C0.646756 9.75697 0.513831 9.70384 0.405181 9.59518C0.296519 9.48653 0.242188 9.3548 0.242188 9.2C0.242188 9.04521 0.296519 8.91348 0.405181 8.80483L4.20999 5L0.405181 1.19518C0.301331 1.09134 0.248206 0.960818 0.245806 0.803605C0.243394 0.646405 0.296519 0.51348 0.405181 0.40483C0.513831 0.296168 0.645556 0.241837 0.800356 0.241837C0.955156 0.241837 1.08688 0.296168 1.19553 0.40483L5.00036 4.20964L8.80518 0.40483C8.90902 0.30098 9.03954 0.247855 9.19675 0.245455C9.35395 0.243043 9.48688 0.296168 9.59553 0.40483C9.70419 0.51348 9.75852 0.645205 9.75852 0.800005C9.75852 0.954805 9.70419 1.08653 9.59553 1.19518L5.79072 5L9.59553 8.80483C9.69938 8.90867 9.7525 9.03919 9.7549 9.19641C9.75732 9.35361 9.70419 9.48653 9.59553 9.59518C9.48688 9.70384 9.35515 9.75817 9.20035 9.75817C9.04555 9.75817 8.91383 9.70384 8.80518 9.59518L5.00036 5.79037Z" fill="currentColor" />
                                </svg>
                            </button>
                            <div className="edit-profile-add-search-wrap">
                                        <input className="edit-profile-add-search" type="search" placeholder={showSkillOverlay ? 'Search' : 'Search or add' } value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                        debounce(() => {
                                            if (showInterestOverlay) {
                                                dispatch(getInterestsList(e.target.value))
                                            }
                                            else if (showSkillOverlay) {
                                                dispatch(getSkillsList(e.target.value))
                                            }
                                        });
                                    }} />
                                <button className="edit-profile-add-submit">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6 11.5333L6.86667 7.8C6.53333 8.06667 6.15 8.27778 5.71667 8.43333C5.28333 8.58889 4.82222 8.66667 4.33333 8.66667C3.12222 8.66667 2.09733 8.24733 1.25867 7.40867C0.419556 6.56956 0 5.54444 0 4.33333C0 3.12222 0.419556 2.09711 1.25867 1.258C2.09733 0.419333 3.12222 0 4.33333 0C5.54444 0 6.56956 0.419333 7.40867 1.258C8.24733 2.09711 8.66667 3.12222 8.66667 4.33333C8.66667 4.82222 8.58889 5.28333 8.43333 5.71667C8.27778 6.15 8.06667 6.53333 7.8 6.86667L11.55 10.6167C11.6722 10.7389 11.7333 10.8889 11.7333 11.0667C11.7333 11.2444 11.6667 11.4 11.5333 11.5333C11.4111 11.6556 11.2556 11.7167 11.0667 11.7167C10.8778 11.7167 10.7222 11.6556 10.6 11.5333ZM4.33333 7.33333C5.16667 7.33333 5.87511 7.04178 6.45867 6.45867C7.04178 5.87511 7.33333 5.16667 7.33333 4.33333C7.33333 3.5 7.04178 2.79156 6.45867 2.208C5.87511 1.62489 5.16667 1.33333 4.33333 1.33333C3.5 1.33333 2.79156 1.62489 2.208 2.208C1.62489 2.79156 1.33333 3.5 1.33333 4.33333C1.33333 5.16667 1.62489 5.87511 2.208 6.45867C2.79156 7.04178 3.5 7.33333 4.33333 7.33333Z" fill="currentColor" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <Scrollbar
                            wrapperProps={{ className: 'custom-scrollbar-wrapper' }}
                            thumbMinSize={30}
                            trackYProps={{ className: 'custom-scrollbar-track-y' }}
                            thumbYProps={{ className: 'custom-scrollbar-thumb-y' }}
                        >
                            <div className="edit-profile-add-bottom">
                        
                                {searchText === "" && popularSection()}
                                {searchText !== "" && searchSection()}
                        
                            </div>
                        </Scrollbar>
                    </div>
                    
                    
                </div>
                    )}
                </Scrollbar>
            </div>
                
        </div>
    );
}